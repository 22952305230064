/* ---------------------- Navbar css -------------------------- */
/* @import url('https://fonts.googleapis.com/css2?family=Zilla+Slab:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap'); */
/* News main nave */
/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Tamil:wght@200;400;500;600&display=swap'); */

/* @import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Tamil:wght@300;400;500;600&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Anek+Tamil:wght@300;400;500;600&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

:root {
  --text-size: 14px;
}

/* :root {
  --text-color-light: #333;
  --background-color-light: #fff;
  --text-color-dark: #fff;
  --background-color-dark: #333;
} */

body {
  margin: 0;
  font-family: 'Noto Sans Tamil', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.5px;
  font-weight: 500;
  /* color: #000 !important; */
  scroll-behavior: smooth;
  scroll-padding-top: 90px;
  word-spacing: 4px;
  line-height: 23px;
}

body {
  color: var(--text-color);
  background: var(--background-color);
}

p {
  /* font-size: 14px; */
  font-family: 'Noto Sans Tamil', sans-serif;
  font-weight: 600;
}

h1 {
  font-family: 'Noto Sans Tamil', sans-serif;
  font-weight: 600;
}

h2 {
  font-family: 'Noto Sans Tamil', sans-serif;
  font-weight: 600;
}

h3 {
  font-family: 'Noto Sans Tamil', sans-serif;
  font-weight: 600;
}

h4 {
  font-family: 'Noto Sans Tamil', sans-serif;
  font-weight: 600;
}

h5 {
  font-family: 'Noto Sans Tamil', sans-serif;
  font-weight: 600;
}

h6 {
  font-family: 'Noto Sans Tamil', sans-serif;
  font-weight: 600;
}

b {
  font-family: 'Noto Sans Tamil', sans-serif;
  font-weight: 900;
}

a {
  font-family: 'Noto Sans Tamil', sans-serif;
  font-weight: 500;
}

#elrhfweiurgwiergewrwheg {
  font-family: 'Mukta Malar', sans-serif !important;
}

#youtube_frame_nv p{
  font-family: 'Mukta Malar', sans-serif !important;
}

#cv-card-body p{
  font-family: 'Mukta Malar', sans-serif !important;
}

#about p{
  font-family: 'Mukta Malar', sans-serif !important;
}

#pp-modal-body p{
  font-family: 'Mukta Malar', sans-serif !important;
}

#btnSignIn span{
  font-size: 14px !important;
}

.card-title.five_line.text-gray p{
  font-family: 'Mukta Malar', sans-serif !important;
}

span {
  font-family: 'Noto Sans Tamil', sans-serif;
  font-weight: 500;
}
@media (max-width: 1200px) {
  .sticky {
    z-index: 999 !important;
  }
}
@media (min-width: 1200px) {

  .h1,
  h1 {
    font-size: 20px !important;
    line-height: 28px;
  }

  .h2,
  h2 {
    font-size: 19px !important;
  }

  .h3,
  h3 {
    font-size: 18px !important;
  }

  .h4,
  h4 {
    font-size: 17px !important;
  }

  .h5,
  h5 {
    font-size: 16px !important;
  }

  .h6,
  h6 {
    font-size: 15px !important;
  }
}

@media (max-width: 1199px) and (min-width: 500px) {

  .h1,
  h1 {
    /* word-break: break-all; */
    font-size: 16px !important;
    line-height: 28px;
  }

  .h2,
  h2 {
    font-size: 16px !important;
  }

  .h3,
  h3 {
    font-size: 16px !important;
  }

  .h4,
  h4 {
    font-size: 16px !important;
  }

  .h5,
  h5 {
    font-size: 15px !important;
  }

  .h6,
  h6 {
    font-size: 14px !important;
  }

}


@media (max-width: 499px) {

  .h1,
  h1 {
    /* word-break: break-all; */
    font-size: 16px !important;
    line-height: 28px;
  }

  .h2,
  h2 {
    font-size: 15px !important;
  }

  .h3,
  h3 {
    font-size: 15px !important;
  }

  .h4,
  h4 {
    font-size: 14px !important;
  }

  .h5,
  h5 {
    font-size: 14px !important;
  }

  .h6,
  h6 {
    font-size: 14px !important;
  }

  p {
    font-size: 14px !important;
  }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: inherit !important;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
.custom-swiper .swiper-pagination-bullet {
  position: relative;
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 10px);
}

.custom-swiper .swiper-pagination-bullet::before {
  position: absolute;
  content: "";
  border: 2px solid #fff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}

.custom-swiper .swiper-pagination-bullet-active {
  background-color: var(--primary-color) !important;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  --bs-gutter-x: 30px;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 576px) {
  .calender_icon svg {
    font-size: 9px !important;
  }

  .calender_icon p {
    font-size: 11px !important;
  }
}

@media (min-width: 576px) {

  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
#sticky-header1.sticky{
position: initial !important;
}
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1440px;
  }
}

.custom-card {
  border-radius: 10px;
  padding: 15px;
}

.custom-card .swiper-button-prev,
.custom-card .swiper-button-next {
  bottom: 0;
  top: auto;
}

.custom-card .swiper-button-prev::after,
.custom-card .swiper-button-next::after {
  font-size: 18px;
  background-color: var(--primary-color);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}

.custom-card .swiper-button-prev {
  left: 46%;
}

.custom-card .swiper-button-next {
  right: 46%;
}

a {
  color: black;
  text-decoration: none !important;
}

#bns-card-body a {
  color: #464242 !important;
  font-size: 12px;
}
@media (max-width: 370px) {
.medicalhdo .nav_position{
display: none;
}
}

@media (max-width: 575px) {
  body .calender_icon{
    min-width: 104px !important;
  }
  #twitter-widget-0 {
    width: 271px !important;
  }

  .trjksgv {
    width: 10%;
  }

  #cv-card-body #cv-card-title {
    -webkit-line-clamp: 1 !important;
  }

  #hns-main-image {
    height: 15rem !important;
  }

  .custom-card .swiper-button-prev {
    left: 40%;
  }

  #NewsLogo {
    /* width: 66px !important; */
    width: 100px !important;
    height: 26px !important;
  }

  .custom-card .swiper-button-next {
    right: 40%;
  }

}

@media (min-width: 576px) and (max-width: 767px) {
  .custom-card .swiper-button-prev {
    left: 42%;
  }

  .custom-card .swiper-button-next {
    right: 42%;
  }
}


@media (min-width: 576px) and (max-width: 767px) {
  #NewsLogo {
    height: 35px !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .custom-card .swiper-button-prev {
    left: 40%;
  }

  #NewsLogo {
    height: 37px !important;
  }

  .custom-card .swiper-button-next {
    right: 40%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  #vps-secondry-images {
    height: 18rem !important;
  }

  #NewsLogo {
    height: 39px !important;
  }

  .custom-card .swiper-button-prev {
    left: 44%;
  }

  .custom-card .swiper-button-next {
    right: 44%;
  }
}

.custom-modal .ant-modal-close {
  position: absolute;
  top: -15px;
  right: -10px;
  background-color: var(--primary-color);
  color: #fff;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 14px;
}

.custom-modal .ant-modal-close:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.custom-modal .ant-modal-close-x {
  line-height: 0;
}

.custom-modal .ant-modal-body {
  overflow-y: scroll;
  height: 600px;
}

/* width */
.custom-modal .ant-modal-body::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.custom-modal .ant-modal-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.custom-modal .ant-modal-body::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}

/* Handle on hover */
.custom-modal .ant-modal-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#tns-modal-header {
  background-color: var(--secondary-color);
  color: #ffffff;
  justify-content: start;
}

.PhoneInputInput {
  flex: 1 1;
  min-width: 0;
  border: 1px solid transparent;
  border-radius: 5px;
  height: 44px;
  padding-left: 10px;
}

.PhoneInputCountryIcon--border {
  height: 44px;
  width: 100%;
  border-radius: 5px !important;
  padding: 5px;
  box-shadow: none;
  background-color: transparent;
}

.PhoneInputCountrySelect:focus+.PhoneInputCountryIcon--border {
  box-shadow: none;
}

/* :focus-visible {
    outline: -webkit-focus-ring-color auto 1px;
} */

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 73vh;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Newsbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-top: 4px solid #00445e;
}

/* News logo */
.navbar_content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#NewsLogo {
  height: 60px;
  object-fit: contain;
  width: 145px;
  margin-bottom: 5px !important;
}

/* manu-links and buttons */
.hamburger-manu {
  display: none;
}

.mobile-manu {
  display: none;
}

.Manu-links ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 0;
  padding-left: 0;
}

.Manu-links ul li {
  list-style-type: none;
  margin-right: 30px;
}

.Manu-links ul li:last-of-type {
  margin-right: 0;
}

[dir="rtl"] .Manu-links ul li:last-of-type {
  margin-right: 30px;
}

.Manu-links ul a {
  text-decoration: none;
}

#nb-popover-body {
  width: 18rem;
  height: fit-content;
  padding: 0%;
  border: none;
}

.nav-item {
  list-style-type: none;
}

#nav-links {
  color: var(--secondary-color);
  position: relative;
  text-decoration: none;
}

@media (max-width: 1200px) {
  #btnSignIn {
    width: auto;
  }

  .Newsbar {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    /* background-color: white; */
    padding: 8px 0 !important;
  }

  #rns-weather-card {
    display: none !important;
  }

  .mobile-manu {
    display: none;
  }

  body .d-none-1200 {
    display: none !important;
  }

  .mobile-manu2 {
    display: contents;
  }

  .Manu-links {
    display: none;
  }

  #cn-main {
    /* display: none; */
  }

  .hamburger-manu {
    display: flex;
  }

  .hamburger-manu svg {
    font-size: 19px;
  }

  #nav-links {
    font-size: 13px !important;
    color: black;
  }

  #catNav-links {
    font-size: 14px !important;
    margin-top: 1%;
  }
  #catCanvas-links {
    font-size: 14px !important;
    margin-top: 1%;
  }
  .catCanvas-links {
    font-size: 14px !important;
    margin-top: 1%;
  }

  .nav-item {
    margin-bottom: 15px;
    list-style-type: none;
    font-size: 12px;
  }

  #Nav-btns {
    list-style-type: none;
  }

  .offcanvas-title {
    display: flex;
    align-items: center;
  }

  .offcanvas-title li {
    margin-right: 10px;
  }

  .menu-toggle {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .menu-toggle i {
    /* background-color: var(--primary-color); */
    color: var(--primary-color);
    border-radius: 50%;
    float: right;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* .has-children .sub-menu li::before {
    content: "⁍";
    font-size: 2rem;
    color: var(--primary-color);
    margin-right: 4px;
  } */
}

#NewsNav {
  width: 100%;
  height: 6rem;
}

#nav-link {
  color: var(--secondary-color);
  text-decoration: none;
}

/* nav buttons */
#btnSignIn {
  background: #168cb9;
  border: none;
  border-radius: 30px;
  color: #fff;
  padding: 9px 12px;
  /* width: 157px; */
  font-size: 11px;
  /* height: 43px; */
}

#btnSignIn:hover {
  background: var(--secondary-color);
}

#btnBookmark {
  text-decoration: none;
  color: #ffffff;
  border: none;
}

#btnBookmark:hover {
  background: var(--primary-color);
}

#btnLogout {
  color: #ffffff;
  border: none;
  font-size: 13px;
}

#btnLogout:hover {
  background: var(--primary-color);
}

.btnSignIn {
  color: #ffffff;
  background: var(--primary-color);
  border: none;
}

.btnSignIn:hover {
  background: var(--secondary-color);
}

#btnNotification {
  color: #00445e;
  background: #f5f5f500;
  border: none;
  position: relative;
  padding: 8px !important;
}

#btnTheme {
  color: #00709c;
  background: #f5f5f500;
  border: none;
  padding: 8px !important;
}

.noti_badge_data {
  position: absolute;
  top: 0px;
  right: 5px;
  border-radius: 50%;
  background: #00445e;
  color: #fff;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 7px !important;
}

.theme_badge_data {
  top: 0px;
  right: 5px;
  border-radius: 50%;
  background: #00445e;
  color: #fff;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 7px !important;
}

#btnSerch {
  color: var(--primary-color);
  background: #f5f5f5;
  margin-right: 110px;
  border: none;
}

#btnSerch:hover {
  color: #ffffff;
  background: var(--secondary-color);
}

#serchModal {
  position: absolute;
  background: #ffffff;
  padding: 0%;
}

#btnSerchForm {
  color: var(--primary-color);
  background: #f5f5f5;
}

/* button logo */
#btnLogo {
  margin-bottom: 2px;
  margin-right: 4px;
}

/* ---------------------- respons Navbar css -------------------------- */
/* ---------------------- Header css -------------------------- */
#Header {
  height: 3rem;
  background-color: var(--secondary-color);
  color: #ffffff;
  gap: 0%;
}

.left-head {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.right-head {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

#head-trend-news {
  margin-top: 20px;
  background: #101d38;
}

/* header buttons */
#btncalendar {
  color: #ffffff;
  background: var(--primary-color);
  justify-content: center;
}

/* logos */
#logoCalendar {
  margin-bottom: 4px;
  margin-right: 7px;
}

#line-head {
  height: 35px;
  width: 0.5px;
  color: #909090;
  background-color: #909090;
}

#btnHead-Socials {
  padding: 0%;
  color: #ffffff;
  background: var(--primary-color);
}

#btnHead-Socials svg {
  margin-bottom: 5%;
}

#drop-head-lang {
  color: #ffffff;
  outline-color: #ffffff;
}

/* ---------------------------------- cat nav --------------------------- */
#cn-main {
  background-color: #00445e;
  box-shadow: none !important;
}

#catNav-links {
  color: #fff !important;
  text-decoration: none;
  margin-right: 1rem;
  margin-left: 1rem;
  font-size: 13px;
  font-weight: 400;
}

#catCanvas-links {
  color: #168cb9 !important;
  text-decoration: none;
  margin-right: 2rem;
  margin-left: 1rem;
  font-size: 13px;
  font-weight: 400;
}

.catCanvas-links {
  color: #168cb9 !important;
  text-decoration: none;
  margin-right: 2rem;
  margin-left: 1rem;
  font-size: 13px;
  font-weight: 400;
}

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@500&display=swap");

#Link-all {
  text-decoration: none;
  color: var(--secondary-color);
}

/* ----------------------------------- first section -----------------------------------  */
#first-section {
  color: var(--secondary-color);
  height: fit-content;
}

#fs-main {
  display: flex;
  flex-direction: column;
}

#body-first-section {
  margin-top: 10px;
}

.custom-swiper h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px !important;
  color: #00445e;
}

#right-first-section {
  width: 96px;
}

.headre_icon {
  width: 35px;
  height: 35px;
  object-fit: cover;
  margin-right: 5px;
}

.custom-swiper {
  max-height: 470px;
  padding: 11px;
  /* max-height: 454px; */
  border-radius: 10px;
  background: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

#Left-first-section {
  background-color: #fff;
  border-radius: 10px;
  height: 100% !important;
  width: auto !important;
  justify-content: center;
  display: flex;
  /* margin-right: -70px; */
  flex-direction: column;
  justify-content: space-between;
}

.custom-swiper .swiper-pagination-bullet {
  height: 23px;
  width: 23px;
}

.custom-swiper .swiper-pagination-fraction,
.custom-swiper .swiper-pagination-custom,
.custom-swiper .swiper-horizontal>.swiper-pagination-bullets,
.custom-swiper .swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 18px !important;
}

#Top-Title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 600;
}

#Top-Description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}

#bns-card-set a {
  color: #675e5e;
  font-size: 13px;
}

#fs-Newscard-image {
  width: 96px;
  height: 81px;
  object-fit: cover;
  border-radius: 10px;
}

@media (max-width: 767px) {


  #BNV-card {
    padding: 7px;
  }

  #LN-card {
    padding: 7px;
  }

  #btnrnsCatagory {
    font-size: 10px !important;
  }

  #cv-btnCatagory {
    font-size: 10px !important;
  }

  #B_NV-left-head {
    display: block !important;
  }

  #bns-main-image {
    height: 16rem !important;
  }

  #fs-Newscard-image {
    /* border-radius: 10px 10px 0 0; */
  }
}

#fs-Radiobtn {
  width: 23px;
  height: 23px;
  accent-color: var(--primary-color);
}

/* buttons */
#btnCatagory {
  color: #ffffff;
  background: var(--primary-color);
  width: fit-content;
  margin-bottom: 5px;
}

#btnReadMore {
  color: #ffffff;
  background: var(--primary-color);
  width: fit-content;
  margin-bottom: 5px;
}

#btnReadMore:hover {
  color: #ffffff;
  background: var(--secondary-color);
}

.circle {
  position: relative;
}

.circle svg {
  color: var(--primary-color);
  opacity: 0.6;
}

.circle:before,
.circle:after {
  content: "";
  display: block;
  position: absolute;
  top: -9px;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid #ff4343;
  border-radius: 50%;
  height: 40px;
}

@media (max-width: 1024px) {
  #vps-main-logo {
    font-size: 15px !important;
  }

  #hns-main-logo {
    font-size: 15px !important;
  }

  #hns-Viewmore {
    font-size: 13px !important;
  }
}

@media (max-width: 575px) {
  #vps-head-main {
    margin: 15px auto !important;
  }

  .circle:before,
  .circle:after {
    top: -9px;
    left: 7px;
  }
}

.circle:before {
  animation: ripple 2s linear infinite;
}

.circle:after {
  animation: ripple 2s linear 1s infinite;
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
    opacity: 1;
  }

  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}

#btnpaly {
  color: var(--secondary-color);
  margin-left: 2%;
  border: none;
}

#btnpaly-mobile {
  display: none;
}

@media (max-width: 1199px) and (min-width: 768px) {
  .home_banner {
    max-height: 470px !important;
    min-height: 417px !important;
  }
}

@media (max-width: 922px) {
  #body-first-section {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media (max-width: 700px) {
  #vps-Viewmore {
    padding: 7px 9px !important;
    font-size: 11px !important;
  }

  .arrow_icon {
    width: 15px !important;
    height: 15px !important;
  }

  #hns-Viewmore {
    padding: 7px 9px !important;
    font-size: 11px !important;
  }

  body h4#rns-main-logo {
    color: #00445e;
    font-size: 15px !important;
    font-weight: 600;
  }

  #fs-Radiobtn {
    width: 20px;
    height: 20px;
    accent-color: var(--primary-color);
  }

  /* buttons */
  #btnCatagory {
    font-size: 12px;
    color: #ffffff;
    background: var(--primary-color);
    width: fit-content;
    margin-bottom: 5px;
  }

  #btnReadMore {
    color: #ffffff;
    background: var(--primary-color);
    width: fit-content;
    font-size: 12px;
    margin-bottom: 5px;
  }

  #btnpaly-mobile {
    display: contents;
    color: var(--secondary-color);
    border: none;
  }

  #btnpaly-logo {
    margin-left: 2%;
  }
}

/* ------------------------------ Breaking News section -------------------------------------*/
#bns-main-mobile {
  display: none;
}

#bns-main {
  color: var(--secondary-color);
  /* padding-top: 2%; */
  height: fit-content;
  padding-bottom: 2%;
  position: relative;
  overflow: hidden;
  background-color: #eef7fa;
}

#bns-main::before {
  /* background-image: url("../images/design-top.svg"); */
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
}

#bns-main::after {
  /* background-image: url("../images/design-bottom.svg"); */
  /* position: absolute;
  content: "";
  right: -70%;
  bottom: -31%;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat; */
}

/* width */
#bns-rest-cards::-webkit-scrollbar {
  height: 5px;
}

/* Track */
#bns-rest-cards::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
#bns-rest-cards::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}

/* Handle on hover */
#bns-rest-cards::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#bns-main-card {
  height: 100%;
  background: black;
  border-radius: 10px;
  overflow: hidden;
}

#bns-card-body {
  border-radius: 10px;
  position: relative;
}

#bns-logo-row {
  display: none;
}

#bns-main-image {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

#bns-logo-col {
  font-size: 20px;
  font-weight: 600;
}

#bns-main-text {
  color: #ffffff;
  font-size: 25px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: #00445e;
}

#bns-rest-cards {
  height: 100%;
}


#bns-card {
  background-color: #fff;
  border: none;
  display: flex;
  align-items: center;
  padding: 0px;
  height: 100%;
}

#bns-card::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 80%;
  background-color: #fff;
  bottom: 0;
  border-radius: 10px;
}

#bns-image {
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 10px 10px 0px 0px;
  position: relative;
}

#bns-card-text {
  margin-top: 10px;
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #000000;
}

#btnbnsCatagory {
  color: #ffffff;
  background: var(--primary-color);
  width: fit-content;
  margin-bottom: 5px;
}

.left-weather p {
  font-size: 12px;
  color: gray;
}

#btnbnsViewAll {
  color: #080707;
  background: #ffffff;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 5px;
  padding: 5px 15px;
  font-size: 14px;
}

#bns-np-btns {
  margin-top: 1%;
  justify-content: center;
  display: flex;
  flex-direction: row;
  gap: 3%;
}

#btnbnsNP {
  color: var(--primary-color);
  background: #edf2f8;
  width: fit-content;
  margin-bottom: 5px;
}

@media (max-width: 1199px) {
  .img_sidebar {
    height: 100% !important;
  }

  #body-first-section .row {
    flex-direction: column-reverse;
  }

  .foot2 {
    /* order: 2; */
    margin-top: 10px !important;
    overflow-x: auto;
  }

  #bns-main {
    color: var(--secondary-color);
    padding-top: 2%;
    height: fit-content;
    padding-bottom: 1%;
  }

  #bns-card-text {
    margin-top: 2%;
    font-size: 16px;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
}

@media (max-width: 1080px) {
  #bns-main {
    color: var(--secondary-color);
    padding-top: 2%;
    height: fit-content;
    padding-bottom: 1%;
  }

  #bns-allCards {
    display: flex;
    flex-direction: column;
    gap: 2%;
  }

  #bns-logo-row {
    display: contents;
  }

  #bns-logo-col {
    display: none;
  }

  #bns-card-text {
    margin-top: 2%;
    font-size: 14px;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
}

@media (max-width: 425px) {
  #bns-card-text {
    margin-top: 2%;
    font-size: 12px;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }

  .card-title.three_line {
    -webkit-line-clamp: 2 !important;
  }
}

/* ------------------------------ Recent news section -------------------------------------*/
#rns-main-mobile {
  display: none;
}

#rns-main {
  color: var(--secondary-color);
  height: fit-content;
}

#rns-head-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;
}

#rns-main-logo {
  position: relative;
}

#rns-Viewmore {
  font-family: "Noto Sans Tamil", sans-serif;
  letter-spacing: 1px;
  color: #fff;
  background-color: var(--primary-color);
  text-decoration: none;
  padding: 7px 15px;
  border-radius: 5px;
}

#rns-card {
  border: 0px !important;
}

#rns-image {
  aspect-ratio: 1/1;
  position: relative;
  /* height: 208px; */
  border-radius: 10px;
  object-fit: cover;
  transition: 0.3s;
}

#rns-image:hover {
  transform: scale(1.02);
}

#rns-card:hover #rns-image {
  transform: scale(1.02);
}


#rns-card-body {
  padding: 10px 10px 10px 0;
  font-size: 24px;
  font-weight: 500;
}

#btnrnsCatagory {
  color: #ffffff;
  background: #0000008c;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  top: 7px;
  right: 7px;
  font-size: 12px;
}

.card-block .card-title {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
}

#btnrnsRead {
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color) 17;
  padding-left: 0;
}

#btnrnsRead:hover {
  color: #ffffff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  padding-left: 10px;
}

#rns-arrow {
  margin-bottom: 1%;
}

#rns-Catagory-main {
  border-radius: 1%;
  height: 40rem;
}

#rns-cat-nav {
  border: 2px solid var(--primary-color);
  border-radius: 3%;
  color: #ffffff;
  background-color: var(--primary-color);
  justify-content: center;
  height: 3rem;
}

#rns-cat-card {
  width: 96%;
  margin-left: 2%;
  margin-top: 3%;
  background: black;
  overflow: hidden;
  border: none;
}

#rns-cat-image {
  object-fit: cover;
  opacity: 0.5;
  height: 5rem;
  width: 100%;
}

#btnrns-cat-more {
  color: #ffffff;
  background-color: #00000000;
  border: none;
}

@media (max-width: 1250px) {
  #rns-card-body {
    font-size: 22px;
  }
}

@media (max-width: 850px) {
  #rns-card-body {
    font-size: 18px;
  }
}

@media (max-width: 700px) {
  #rns-card-body {
    font-size: 14px;
  }
}

@media (max-width: 450px) {
  #rns-Viewmore {
    text-align: end;
    font-size: 12px;
  }

  #rns-card-body {
    font-size: 12px;
  }
}

/* ---------------------------------- rns weather card ------------------------------------ */
#rns-weather-card {
  background: var(--secondary-color);
  padding: 5px 0;
}

@media (max-width: 991px) {
  .ModalWrapper {
    display: block !important;
  }

  #rns-weather-card {
    display: none;
  }

  #login_img2 #ModalImg2 {
    display: none !important;
  }
}

.calender_icon {
  border-radius: 5px;
  padding: 4px;
  display: flex;
  align-items: center;
  min-width: 124px !important;
}

.calender_icon p {
  font-weight: 500;
  font-size: 13px;
}

.calender_icon svg {
  margin-right: 5px;
}

.location-wcard {
  font-weight: bold;
}

.location-wcard,
.day-Wtype-wcard {
  font-size: small;
}

.weather_icon {
  margin-right: 5px;
  height: 30px;
  border-radius: 50%;
}

#weather-main-text {
  color: #fff;
  width: 100%;
}

.left-weather {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-width: 120px;
}

.slash-line {
  width: 1px;
  background-color: #fff;
  content: "|";
  position: relative;
  height: 20px;
}

.social_media_top {
  margin-left: 10px;
}

.language_section {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.right-weather {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

[dir="rtl"] .left-state {
  margin-right: 10px;
}

.language_drop {
  background-color: transparent !important;
  outline: none !important;
  border: none !important;
}

#wcard-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 33%;
  width: 100%;
  background-color: #ffffff49;
  display: flex;
  flex-direction: row;
  gap: 2.5%;
  justify-content: center;
  padding-top: 4%;
}

/* ----------------------------------------- Popular News --------------------------------------- */
#rns-PNews-main {
  border-radius: 1%;
  height: 38rem;
}

#rns-PNews-card {
  width: 96%;
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 3%;
  background: #ffffff;
  overflow: hidden;
  border: none;
  display: flex;
  flex-direction: row;
}

#rns-PNews-image {
  object-fit: cover;
  height: 7rem;
  width: 30%;
  border-radius: 3%;
}

#PNews-card-body {
  padding-left: 2%;
}

#rns-PNews-card-text {
  margin: 4%;
}

#nav-logo {
  color: #00445e;
}

#rbn-card-text {
  color: #000;
}

/* -------------------------------------------- tags ----------------------------------------- */
/* #rns-tags-main {
  background-color: #ffffff;
  border-radius: 5px;
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 13px;
} */
#rns-nav-logo {
  margin-bottom: 0px;
  color: #00445e !important;
}

#btnTags {
  margin-right: 2%;
  margin-top: 2%;
  font-size: 13px;
  background-color: #168cb9;
  border: 1px solid #168cb9;
  color: #fff !important;
  transition: 0.6 !important;
}

#btnTags:hover {
  margin-right: 2%;
  margin-top: 2%;
  font-size: 13px;
  background-color: #fff;
  border: 1px solid #168cb9;
  color: #168cb9 !important;
}

/* -----------------------------video player sectin ---------------------------------- */
#vps-main {
  height: fit-content;
  color: var(--secondary-color);
  color: #ffffff;
}

#vps-head-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px auto;
}

#rns-main-logo {
  color: #00445e;
  font-size: 18px !important;
  font-weight: 600;
}

#vps-main-logo {
  position: relative;
  color: #000;
  font-size: 19px;
  font-weight: 600;
  line-height: 1.2;
}

#vps-Viewmore {
  letter-spacing: 1px;
  color: #111111;
  background-color: #ececec;
  text-decoration: none;
  padding: 7px 15px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
  position: relative;
  z-index: 1;
}

@media (max-width: 400px) {
  #vps-Viewmore {
    font-size: small;
  }
}

#vps-main-card {
  width: 100%;
  border: none;
}

#vps-card-overlay {
  height: 65%;
}

#vps-main-image {
  object-fit: cover;
  opacity: 0.8;
  border-radius: 10px;
  width: 100%;
  position: relative;
}

@media (max-width: 1199px) {
  #vps-main-image {
    height: 20rem;
  }
}

#vps-card-body {
  height: 35%;
  width: 100%;
}

#vps-main-title {
  color: #ffffff;
  margin-left: 1%;
  position: relative;
  font-size: 30px;
  top: 30%;
}

#vps-main-date {
  color: #ffffff;
  position: absolute;
  bottom: 3%;
  font-size: 1.2rem;
  margin-left: 1%;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

#vps-body-right {
  display: flex;
  flex-direction: column;
}

#vps-image-cards {
  background-color: #fff;
  border-radius: 10px;
  border: none;
}

#vps-secondry-images {
  object-fit: cover;
  border-radius: 10px;
  height: 21rem;
  width: 100%;
}

.second_video #vps-btnVideo,
.third_video #vps-btnVideo {
  top: 9%;
}

.second_video #vps-card-title,
.third_video #vps-card-title {
  bottom: 8%;
}

#vps-card-title {
  position: absolute;
  bottom: 4%;
  left: 0;
  padding: 5%;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  line-height: 1.2;
  border-radius: 10px;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  right: 0;
}

@media (max-width: 1200px) {
  #vps-main-title {
    top: 20%;
  }

  #catNav-links {
    color: #fff !important;
  }
  #catNav-links:hover {
    color: #288bb1 !important;
  }
}

@media (max-width: 400px) {
  #vps-card-overlay {
    height: 65%;
  }

  #vps-main-image {
    object-fit: cover;
    opacity: 0.8;
    border-radius: 10px;
    height: auto;
    width: 100%;
    aspect-ratio: 1/1;
  }

  #vps-image-cards {
    background-color: #000000;
    border-radius: 10px;
    border: none;
  }
}

/* -----------------------------modal css -------------------------------------------------- */
#vps-modal-btnclose {
  border: none;
  position: absolute;
  background: var(--primary-color);
  box-shadow: 1px 1px 5px 0px #000000;
  top: -12px;
  right: -12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#btnClose-logo {
  color: #ffffff;
  border-radius: 10px;
  width: 10px;
}

#vps-modal-header {
  border: none;
}

#vps-modal-body {
  padding: 0;
}

#vps-btnVideo {
  color: #ffffff;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

#vps-btnVideo:hover {
  box-shadow: 0 0 1px 8px rgba(255, 255, 255, 0.4),
    0 0 1px 16px rgba(255, 255, 255, 0.1);
}

#vps-btnVideo-logo {
  width: 20px;
  height: 20px;
}

#vps-videoPlayer {
  padding: 3% 3% 1% 3%;
}

#vps-multi-video {
  display: flex;
  flex-direction: row;
  gap: 3%;
  margin-top: 1%;
  height: 12rem;
  justify-content: center;
}

#multi-video-btn {
  display: flex;
  flex-direction: row;
  width: 25%;
  height: 10rem;
  border: none;
}

#vns-btn-image {
  height: auto;
  opacity: 0.3;
  width: 33%;
  border-radius: 50%;
  object-fit: cover;
  margin-top: 0.5%;
}

#vns-btn-text {
  margin-top: 7%;
  bottom: 7%;
}

#vns-btn-date {
  position: absolute;
  margin-top: 0.5%;
  margin-left: 1%;
  font-size: small;
}

/* -----------------------------health news section------------------------- */
#hns-head {
  color: var(--secondary-color);
}

#hns-head-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#hns-main-logo {
  color: #00445e !important;
  font-size: 18px;
  font-weight: 600;
}

#hns-Viewmore {
  letter-spacing: 1px;
  color: #000;
  background-color: #ececec;
  text-decoration: none;
  padding: 7px 15px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
}

#hns-Viewmore:hover {
  background-color: #cce4ed !important;
}

#hns-main {
  margin-top: 20px;
  color: var(--secondary-color);
  height: fit-content;
  padding-bottom: 10px;
}

#hns-main-mobile {
  display: none;
}

#hns-main-card {
  /* overflow: hidden; */
  border: none;
}

#hns-main-image {
  object-fit: cover;
  width: 100%;
  height: 18.6rem;
  transition: 0.3s;
  object-position: top;
}

.card_theme:hover #hns-main-image {
  transform: scale(1.01);
}

#hns-main-image:hover {
  transform: scale(1.01);
}

.hns-right-col .mb-3:last-child {
  margin-bottom: 0px !important;
}

#hns-main-body {
  color: #ffffff;
}

#hns-main-text {
  position: absolute;
  bottom: 2%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 3%;
  width: 90%;
  border-radius: 10px;
  text-align: center;
  margin: auto;
  left: 0;
  right: 0;
}

#hns-card {
  width: 100%;
  height: 80px;
  border: none;
  display: flex;
  flex-direction: row;
  margin-bottom: 18px;
}

#hns-card-image {
  object-fit: cover;
  height: 80px;
  border-radius: 5px;
  width: 80px;
  transition: 0.3s;
}

#hns-card-image:hover {
  transform: scale(1.01);
}

.hns-card-body {
  /* width: 100%; */
  padding-left: 10px;
}

[dir="rtl"] .hns-card-body {
  margin-right: 10px;
}

[dir="rtl"] .modal-header .btn-close {
  margin: 0 !important;
}

#hns-card-text {
  font-size: 14px !important;
  font-family: 'Mukta Malar', sans-serif !important;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

#btnhnsCatagory {
  color: #ffffff;
  background: var(--primary-color);
  margin-left: 10px;
}

#btnhnsCatagorysub {
  color: #ffffff;
  background: var(--primary-color);
}

@media (max-width: 1420px) {
  #hns-card-text {
    font-weight: 500;
  }

  #btnhnsCatagorysub {
    color: #ffffff;
    background: var(--primary-color);
  }
}

@media (max-width: 600px) {
  #cv-Rcomment{
    display: block !important;
  }
  #hns-main-logo {
    position: relative;
    font-size: 17px;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
  }

  #main_contact_us h4 {
    margin-top: 17px !important;
  }

  #hns-Viewmore {
    font-size: 14px;
  }
}

#footer-nav {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}

#footer {
  padding-top: 30px;
  background: #222222;
  height: fit-content;
  width: 100%;
}

#footer .hr_line {
  border-top: 1px solid white !important;
}

.News {
  color: #ffffff;
}

#cB {
  top: 4%;
  width: 76%;
  margin-left: 12%;
  color: #ffffff;
  background-color: #0d2047;
  border: 2px solid #ffffff;
}


#footer p {
  font-size: 13px !important;
}

#footer .resd a {
  color: #c5c5c5;
}

#footer a {
  text-decoration: none !important;
  text-transform: capitalize;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  font-size: 13px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

#footer .category-list,
.newscate {
  column-count: 2;
  column-gap: 25px;
}

@media (max-width: 991px) {

  #footer .category-list,
  .newscate {
    column-count: 1;
    column-gap: 0;
  }
}

.social_media {
  display: flex;
  flex-direction: column;
}

.lorem {
  color: #fff;
  font-size: 13px;
  /* margin-top: 35px; */
}

.hide-mobile a:hover {
  color: #ffffff;
}

#footer ul.newscate {
  list-style-type: none;
  padding-left: 0px;
}

ul.newscate>li {
  display: flex;
  align-items: center;
  font-size: 14px;
}

ul.newscate>li:before {
  content: "⁍";
  color: #c5c5c5;
  text-indent: -5px;
  margin-right: 4px;
  display: none;
}

/* usefull link */
#footer ul.useL {
  list-style-type: none;
  padding-left: 0px;
}

ul.useL>li {
  display: flex;
  align-items: center;
  font-size: 14px;
}

#footer .fa-circle {
  font-size: 3px;
  margin-right: 5px;
  margin-top: 4px;
}

ul.useL>li:before {
  content: "⁍";
  color: #c5c5c5;
  margin-right: 4px;
  display: none;
}

@media only screen and (max-width: 500px) {
  #cv-card-image {
    max-height: 119px !important;
  }

  body #vps-secondry-images {
    height: 10rem !important;
  }
}


@media only screen and (max-width: 1300px) and (min-width: 690px) {
  #footer ul.useL {
    list-style-type: none;
  }
}

#footer .copyright {
  align-items: center;
  justify-content: space-between;
}

#e-f {
  height: 60px;
  width: 500px;
  position: relative;
  top: 2rem;
}

.hr_line {
  background-color: #fff;
}

.newsLetter {
  font-size: small;
}

.error-msg-sub {
  padding-top: 0%;
  color: var(--primary-color);
}

@media only screen and (max-width: 1200px) and (min-width: 1100px) {
  #e-f {
    width: 330px;
  }

  .newsLetter {
    font-size: 10px;
  }
}

@media (max-width: 1100px) {
  #cB {
    display: none;
  }
}

#ES {
  position: relative;
  float: right;
  right: 10px;
  top: -22px;
  margin: 5px;
  color: #fff;
  background-color: var(--primary-color);
  height: auto;
  width: fit-content;
}

#ES:hover {
  background-color: var(--secondary-color);
}

#footer .copyright p {
  color: #fff;
  text-transform: none;
  font-size: 13px;
  cursor: pointer;
  padding-left: 8px;
  margin-bottom: 0px;
}

#social_platforms {
  color: #fff;
  background-color: var(--secondary-color);
}
.footer_social_media {
  color: #fff;
  /* background-color: var(--secondary-color); */
}
.footer_social_media:hover {
  color: #000 !important;
  background-color: #fff !important;
}

a#social_platforms svg:hover {
  color: #ffffff;
  background-color: var(--primary-color);
}

#contact-us {
  display: none;
}

@media only screen and (max-width: 945px) and (min-width: 700px) {
  #footer-nav {
    color: #fff;
    font-size: 16px;
    text-transform: uppercase;
  }

  #contact-us {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 700px) {
  #footer {
    height: fit-content;
    width: 100%;
  }

  #cB {
    top: 4%;
    width: 76%;
    margin-left: 12%;
    color: #ffffff;
    background-color: #0d2047;
    border: 2px solid #ffffff;
  }

  #footer a {
    color: #c5c5c5;
    text-decoration: none !important;
    text-transform: capitalize;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
    display: flex;
    align-items: center;
  }

  #footer a:hover {
    color: var(--primary-color);
  }

  #footer ul.newscate {
    list-style-type: none;
  }

  #footer ul.useL {
    list-style-type: none;
  }

  #footer .copyright {
    /* display: flex; */
    flex-direction: column;
    align-items: flex-start;
  }

  #e-f {
    height: 90%;
    width: 500px;
  }

  .newsLetter {
    font-size: small;
  }

  .error-msg-sub {
    padding-top: 2%;
  }

  #ES {
    position: relative;
    right: 160px;
    top: 10%;
    color: #fff;
    background-color: var(--primary-color);
    height: 70%;
    width: 150px;
  }

  #ES:hover {
    background-color: var(--secondary-color);
  }

  .news_footer {
    margin-top: 3%;
    margin-left: 5%;
    margin-right: 5%;
  }

  #social_platforms {
    color: #fff;
    background-color: var(--secondary-color);
    width: fit-content;
  }

  a#social_platforms:hover {
    color: #ffffff;
    background-color: var(--primary-color);
  }

  #cB {
    display: none;
  }
}

@media (max-width: 570px) {
  #contact-us {
    display: flex;
    flex-direction: row;
  }
}

/* font-family: 'Zilla Slab', serif; */
#bs-no-main {
  text-align: center;
  background-color: #f5f5f5;
}

#bs-no-image {
  width: 15rem;
}

#bs-no-title {
  font-size: 40px;
}

#bs-no-text {
  font-size: 20px;
}

#bs-card {
  width: 100%;
  border: none;
  margin-top: 1%;
}

#bs-card-image {
  aspect-ratio: 1/1;
  object-fit: cover;
  position: relative;
}

#bs-card-body {
  padding: 1%;
  height: 40%;
}

#bs-btnBookmark {
  background-color: var(--secondary-color);
  color: #ffffff;
  border: 3px solid #ffffff;
  border-radius: 50%;
  position: absolute;
  right: 5%;
  top: 60%;
  width: 40px;
  height: 40px;
  padding: 0;
}

#bs-btnCatagory {
  color: #ffffff;
  background: var(--primary-color);
  position: absolute;
  top: 10px;
  left: 10px;
}

#bs-card-title {
  margin-top: 2%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

#bs-card-date {
  font-size: large;
}

#bs-logoCalendar {
  margin-bottom: 0.6%;
  margin-right: 1%;
}

.bs-ReadMore {
  position: absolute;
  bottom: 3%;
  right: 1%;
  color: #ffffff !important;
  background-color: var(--primary-color) !important;
}

.bs-ReadMore:hover {
  color: #ffffff !important;
  background-color: var(--primary-color) !important;
}

.breadcrumb-item+.breadcrumb-item:before {
  content: var(--bs-breadcrumb-divider, "|");
}

#bcb-main {
  background: #f5f5f5;
  padding-top: 1.5rem;
  padding-bottom: 0.1rem;
  font-size: 20px;
}

#bcb-home-logo {
  margin-bottom: 10%;
}

#bcb-main [role="button"] {
  text-decoration: none;
}

#bcb-link-text {
  text-decoration: none;
  /* color: var(--secondary-color); */
  color: #000 !important;
}

#bcb-item {
  text-decoration: none;
}

#bcb-active-item {
  color: #168cb9;
  font-weight: 600;
}

.breadcrumb-item.active {
  color: rgb(0 0 0);
  font-weight: 500;
  font-size: 14px;
}

#bcb-active-item::before {
  content: var(--bs-breadcrumb-divider, "|");
}

#bcb-third-item::before {
  content: var(--bs-breadcrumb-divider, "|");
}

.breadcrumb_data {
  display: flex;
  align-items: center;
  font-size: 14px !important;
  font-weight: 600;
  flex-wrap: wrap;
}

.terms_privacy p {
  margin-bottom: 0px !important;
}

#B_NV-main {
  color: var(--secondary-color);
}

#B_NV-page {
  margin-top: 25px;
}

#btnB_NVCatagory {
  color: #ffffff;
  /* background: var(--primary-color); */
  background: #168cb9;
}

#B_NV-right-section {
  display: flex;
  flex-direction: column;
  gap: 1%;
}

#B_NV-body {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 13px;
  border-radius: 10px;
}

#nv-body {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 13px;
  border-radius: 10px;
  margin-bottom: 20px;
}

#B_NV-title {
  margin-top: 10px;
  font-size: 21px;
  color: black;
  font-weight: 600;
}

/* ------------------------------------------------------------------- */
#B_NV-Header {
  display: flex;
  justify-content: space-between;
  height: 3rem;
  background-color: #ffffff;
  color: var(--secondary-color);
}

#B_NV-left-head {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

#B_NV-right-head {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

#B_NV-Share-Label {
  margin: 0;
  color: black;
}

#btncalendar {
  color: #ffffff;
  background: var(--primary-color);
  justify-content: center;
}

#head-lables {
  font-size: 16px;
}

#head-logos {
  margin-bottom: 3px;
}

#line-head {
  height: 35px;
  width: 0.5px;
  color: #909090;
  background-color: #909090;
}

#B_NV-image {
  margin-top: 1%;
  margin-bottom: 1%;
  object-fit: cover;
  width: 100%;
  border-radius: 10px;
  max-width: 100%;
  height: auto;
  max-height: 400px;
}

#B_NV-functions {
  border-radius: 10px;
  background-color: #f5f5f5;
  padding: 15px;
}

#B_NV-functions-left {
  display: flex;
  flex-direction: column;
}

#B_NV-function-pair {
  margin-left: 10%;
  margin-top: 1.7rem;
  justify-content: center;
}

#B_NV-function {
  border: none;
  padding: 0%;
  color: var(--secondary-color);
  text-decoration: none;
  margin: 1%;
}

#B_NV-function-text {
  font-size: small;
}

#B_NV-font-lable {
  margin-top: 0.5rem;
}

#B_NV-FontRange {
  color: var(--secondary-color);
  width: 100%;
}

#B_NV-FontRange-labels {
  font-size: small;
}

#B_NV-functions-right {
  display: none;
  flex-direction: row;
  justify-content: right;
}

#B_NV-description {
  margin-top: 20px;
  width: 100%;
}

#B_NV-description img {
  max-width: 100% !important;
  height: auto;
}

#B_NV-description video {
  max-width: 100%;
  max-height: 40rem;
  height: auto;
}

#BNV-main {
  margin-top: 2%;
}

#BNV-card {
  width: 100%;
  border: none;
  background-color: #ffffff;
}

#BNV-card-image {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 10px;
}

#BNV-card-body {
  padding: 0%;
  height: 30%;
}

#BNV-btnCatagory {
  margin-top: 3%;
  color: #ffffff;
  background: var(--primary-color);
  width: fit-content;
}

#BNV-card-title {
  margin-top: 11px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}

#btnBNVRead {
  color: var(--primary-color);
  background: #d1d1d1;
  border: none;
}

#BNV-card-date {
  font-size: large;
}

#BNV-logoCalendar {
  margin-bottom: 0.6%;
  margin-right: 1%;
}

#BNV-btnVideo {
  float: inline-end;
  color: #ffffff;
  background: #fff;
  border-radius: 50%;
  display: inline-flex;
  position: absolute;
  right: 5%;
  top: 5%;
}

#BNV-btnVideo:hover {
  box-shadow: 0 0 1px 8px rgba(255, 255, 255, 0.4),
    0 0 1px 16px rgba(255, 255, 255, 0.1);
}

#BNV-btnVideo-logo {
  margin-left: 10%;
}

#cat-main {}

#cat-section-card {
  width: 96%;
  margin-left: 2%;
  margin-top: 3%;
  background: black;
  overflow: hidden;
  border: none;
}

#cat-section-card-image {
  object-fit: cover;
  opacity: 0.3;
  height: 5rem;
  width: 100%;
}

#cat-section-card-body {
  color: #ffffff;
  display: grid;
  grid-template-columns: 2% 7fr 1fr;
}

#cat-card-text {
  grid-column: 2/3;
  margin-top: 2%;
}

#btn-cat-more {
  color: #ffffff;
  background-color: #00000000;
  border: none;
}

#cv-main {
  color: #ffffff;
  background: #fff;
  /* border-radius: 50%; */
  /* display: inline-flex; */
  /* position: absolute; */
  /* right: 44%;
  top: 41% */
}

#cv-card {
  width: 100%;
  border: none;
  height: 100%;
}

#cv-card-image {
  width: 100%;
  aspect-ratio: 1/0.7;
  object-fit: cover;
  position: relative;
  border-radius: 10px;
}

#cv-card-body {
  padding: 1%;
  height: 40%;
}

#cv-btnCatagory {
  color: #ffffff;
  background: #168cb9;
  width: fit-content;
  /* position: absolute; */
  top: 10px;
  left: 10px;
  font-size: 12px;
}

#cv-card-title {
  margin-top: 2%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

#btncvRead {
  color: var(--primary-color);
  background: #d1d1d1;
  border: none;
}

#cv-logoCalendar {
  margin-bottom: 0.6%;
  margin-right: 1%;
}

@media (max-width: 1250px) {
  #cv-card-date {
    font-size: 13px;
  }
}
@media (max-width: 425px) {
  #cv-card-date {
    font-size: 12px !important;
  }
}

@media (max-width: 1090px) {
  #cv-card-title {
    font-size: 14px;
    font-weight: 500;
    margin-top: 2%;
  }

  #cv-card-date {
    font-size: 13px;
  }
}

@media (max-width: 850px) {
  #cv-btnCatagory {
    color: #ffffff;
    padding: 1%;
  }
}

@media (max-width: 770px) {
  #cv-card {
    width: 100%;
    border: none;
    margin-top: 1%;
    flex-direction: row;
  }

  #cv-card-image {
    max-width: 30%;
    height: 100%;
    object-fit: cover;
  }

  #cv-card-body {
    padding: 1%;
    width: 70%;
    height: 100%;
  }
}

@media (max-width: 425px) {
  #cv-card {
    width: 100%;
    border: none;
    margin-top: 1%;
    flex-direction: row;
    margin-bottom: 10px;
  }

  #cv-card-image {
    max-width: 30%;
    height: 100%;
    object-fit: cover;
  }

  #cv-card-body {
    padding: 1%;
    width: 70%;
    height: 100%;
  }

  #cv-btnCatagory {
    position: relative;
    top: 0;
    left: 0;
    color: #ffffff;
    padding: 1%;
    width: fit-content;
  }

  #cv-card-title {
    font-weight: 500;
    margin-top: 2%;
  }
}

#submitbutton {
  background-color: #168cb9;
  color: #fff;
  width: auto !important;
  font-weight: 400;
  font-size: 13px;
  text-transform: uppercase;
  padding: 9px 25px;
}

@media screen and (min-width: 992px) {
  #ModalWrapper11 {
    min-width: 820px;
    height: fit-content;
    display: flex;
    flex-direction: row;
    background-color: var(--primary-color);
    border-radius: 20px;
  }

  .logo-text2 {
    color: #ffffff;
    text-align: center;
    width: 50%;
    position: absolute;
    bottom: 3%;
  }

  #logo2 {
    width: 25%;
    height: auto;
  }

  #ModalImg2 {
    width: 100%;
    height: 39rem;
    border-radius: 9px 0px 0px 9px;
    object-fit: cover;
  }

  #modal-content2 {
    width: 100%;
    background-color: #ffffff;
    border-radius: 0 9px 9px 0;
  }
}

@media screen and (max-width: 700px) {
  #login_img2 {
    display: none;
  }

  #ModalWrapper {
    background-color: #ffffff;
  }
}

#BNV-card {
  padding: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

#LN-card {
  width: 100%;
  border: none;
  background-color: #ffffff;
  padding: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

#bs-card {
  padding: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

#LN-card-image {
  width: 100%;
  aspect-ratio: 1/1;
  object-fit: cover;
}

#LN-card-body {
  padding: 0%;
  height: 30%;
}

#vnv-card {
  padding: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

#rnv-card {
  padding: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

#ts-card {
  padding: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

#LN-btnCatagory {
  margin-top: 3%;
  color: #ffffff;
  background: var(--primary-color);
  width: fit-content;
}

#LN-card-title {
  margin-top: 4%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 0px;
}

#btnLNRead {
  color: var(--primary-color);
  background: #d1d1d1;
  border: none;
}

#LN-card-date {
  font-size: large;
}

#LN-logoCalendar {
  margin-bottom: 0.6%;
  margin-right: 1%;
}

#LN-btnVideo {
  float: inline-end;
  color: #ffffff;
  background: #fff;
  border-radius: 50%;
  display: inline-flex;
  position: absolute;
  right: 9%;
  top: 9%;
}

#LN-btnVideo:hover {
  box-shadow: 0 0 1px 8px rgba(255, 255, 255, 0.4),
    0 0 1px 16px rgba(255, 255, 255, 0.1);
}

#LN-btnVideo-logo {
  margin-left: 10%;
}

@media screen and (min-width: 992px) {
  .fonton {
    background-color: #edf2f8;
  }

  .welcom-back {
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .bordert:after {
    content: "(OR)";
    position: absolute;
    top: -13px;
    left: 45%;
    background-color: #fff;
    padding: 0px 8px;
  }

  .logo-img-overlay {
    position: absolute;
    top: 3%;
    left: 3%;
  }

  .logo-text {
    color: #000;
    text-align: center;
    width: 50%;
    position: absolute;
    bottom: 3%;
  }

  #logo1 {
    width: 25%;
    height: auto;
  }

  .ModalImg {
    width: 100%;
    height: 35.5rem;
    border-radius: 20px 0 0 20px !important;
    object-fit: cover;
  }

  [dir="rtl"] .ModalImg {
    border-radius: 0px 20px 20px 0px;
  }

  #mainmodal {
    margin-top: 2%;
    margin-right: 37rem !important;
    margin-left: 20rem !important;
  }

  .footer {
    text-align: center;
    font-size: 120%;
  }

  #loginbutton {
    color: #ffffff;
    background: #168cb9;
    cursor: pointer;
  }

  #loginbutton:hover {
    background-color: #fff;
    color: #168cb9;
    border: #168cb9 solid 1px;
  }
}

#login-social1,
#login-social2,
#login-social3 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 10px;
  font-size: 11px;
}

#login-social1 svg,
#login-social2 svg,
#login-social3 svg {
  height: 10px;
  margin-right: 3px;
}

#login-social1 p,
#login-social2 p,
#login-social3 p {
  margin-bottom: 0;
  font-size: 11px;
}

.error-msg {
  font-size: 12px !important;
  color: rgb(250, 50, 50);
  text-align: justify;
  margin-left: 0.5rem;
}

#login-social1 {
  font-size: 10px;
  border: none;
  font-size: 12px;
  color: #ffffff;
  background-color: #3b5998;
  padding: 10px;
  width: 100%;
}

#login-social2 {
  font-size: 12px;
  color: #ffffff;
  background-color: var(--primary-color);
  padding: 10px;
  width: 100%;
  border: none;
}

#login-social3 {
  border: none;
  font-size: 12px;
  color: #ffffff;
  background-color: var(--secondary-color);
  padding: 10px;
  width: 100%;
}

@media screen and (max-width: 1199px) {
  #ModalWrapper {
    background-color: #ffffff;
    border-radius: 20px;
  }

  #login_img1 {
    display: none;
  }

  #modal-content {
    border-radius: 20px;
  }

  #loginbutton {
    color: #ffffff;
    background: #168cb9;
    cursor: pointer;
  }

  #loginbutton:hover {
    background-color: #fff;
    color: #168cb9;
    border: #168cb9 solid 1px;
  }

  #forgot {
    font: "small-caption";
    /* float: right;
    right: 0; */
    cursor: pointer;
  }

  .footer {
    text-align: center;
    font-size: 120%;
    padding-top: 4%;
  }

  .bordert {
    border-top: 1px solid #aaa;
  }

  .bordert:after {
    content: "(OR)";
    position: absolute;
    top: 72.5%;
    left: 46%;
    background-color: #ffffff;
    padding: 0px 8px;
  }
}

@media screen and (max-width: 575px) {
  .bordert:after {
    content: "(OR)";
    position: absolute;
    top: 72%;
    left: 43%;
    background-color: #ffffff;
    padding: 0px 8px;
  }
}

#nv-main {
  color: var(--secondary-color);
}

#nv-page {
  margin-top: 25px;
}

#btnnvCatagory {
  color: #ffffff;
  background: #168cb9;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 12px !important;
}

#nv-right-section {
  display: flex;
  flex-direction: column;
}

#nv-title {
  margin-top: 1%;
  color: black;
  font-weight: 600;
}

/* ------------------------------------------------------------------- */
#nv-Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  color: var(--secondary-color);
  padding: 10px 0;
}

@media (max-width: 575px) {
  #nv-Header {
    display: flex;
    align-items: flex-start;
    padding: 20px 0;
  }
}

#nv-left-head {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

#nv-right-head {
  display: flex;
  flex-direction: row;
  gap: 9px;
  align-items: center;
}

#btncalendar {
  color: #ffffff;
  background: var(--primary-color);
  justify-content: center;
}

#nv-Share-Label {
  margin-bottom: 0;
  line-height: 0;
  color: #808080;
}

#head-lables {
  font-size: 13px;
  color: #808080;
  margin-bottom: 0;
  background: #1473e6;
  color: white;
  padding: 6px 14px;
  border-radius: 4px;
}

#head-logos {
  margin-bottom: 3px;
}

#line-head {
  height: 35px;
  width: 0.5px;
  color: #909090;
  background-color: #909090;
}

#nv-image {
  object-fit: cover;
  width: 100%;
  border-radius: 10px;
  max-width: 100%;
  height: auto;
  max-height: 400px;
}

#nv-functions {
  border-radius: 10px;
  justify-content: center;
}

#nv-functions-left {
  display: flex;
  flex-direction: column;
}

#nv-function {
  border: none;
  padding: 0%;
  color: var(--secondary-color);
  text-decoration: none;
  margin: 1%;
}

#nv-function-text {
  font-size: small;
  margin-bottom: 0;
}

#nv-font-lable {
  margin-top: 0.5rem;
}

#nv-FontRange {
  color: var(--secondary-color);
  width: 100%;
}

#nv-FontRange-labels {
  font-size: small;
}

.custom-font {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}

#nv-functions-right {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

#nv-description {
  margin-top: 3%;
  width: 100%;
}

.nv-description-14 {
  font-size: 14px;
}

.nv-description-16 {
  font-size: 16px;
}

.nv-description-18 {
  font-size: 18px;
}

.nv-description-20 {
  font-size: 20px;
}

.nv-description-22 {
  font-size: 22px;
}

.nv-description-24 {
  font-size: 24px;
}

#nv-description img {
  max-width: 100% !important;
  height: auto;
}

#nv-description video {
  max-width: 100%;
  max-height: 40rem;
  height: auto;
}

/* ---------------------------------- comments ---------------------------------------------- */
#cs-main {
  display: flex;
  flex-direction: column;
}

#cs-reply-input {
  width: 16rem;
  border: 1px solid #c1c1c1 !important;
  margin: 7px;
  border-radius: 5px;
}

#cs-btnsub {
  margin-top: 1rem;
  background-color: #168cb9;
  color: #ffffff;
  width: fit-content;
  font-size: 13px;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 9px;
}

#cv-comment {
  display: flex;
  flex-direction: row;
  gap: 3%;
  margin-bottom: 2%;
}

#cs-profile {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 50%;
}

#cs-profile:before {
  content: "!";
  font-size: 64px;
  padding-left: 43%;
  background-color: #f5f5f5;
  display: block;
  width: 200px;
  height: 200px;
}

#cs-card {
  /* padding: 1%; */
  /* background-color: #f5f5f5; */
  width: 87%;
  border: 0px;
}

@media (max-width: 575px) {
  #cv-comment {
    display: flex;
    flex-direction: column;
    gap: 3%;
    margin-bottom: 2%;
  }

  /* #cs-profile {
    width: 100%;
    aspect-ratio: 1/1;
    height: auto;
    object-fit: cover;
    margin-bottom: 20px;
  } */

  #cs-card {
    width: 100%;
  }
}

#cdbtnReport {
  text-decoration: none;
  color: var(--primary-color);
  position: absolute;
  right: 1%;
  top: 2%;
}

#csbtnLike {
  width: fit-content;
  border: none;
}

.cdbtnReplay {
  width: -moz-fit-content;
  width: fit-content;
  background-color: #000000;
  color: #828282 !important;
  border: none !important;
  /* position: absolute; */
  background: transparent !important;
  /* right: 1%; */
  /* bottom: 6%; */
  padding: 0px !important;
  font-size: 12px !important;

}

#cv-replay-propover {
  width: 17rem;
  padding: 0%;
}

#cv-replay-form {
  width: 17rem;
}

#cdbtnsubReplay {
  /* width: 100%; */
  background-color: var(--secondary-color);
  color: #ffffff;
  font-size: 12px !important;
  margin: 10px;
  border: none;
}

#cdbtnsubReplay:hover {
  background-color: var(--primary-color);
  color: #ffffff;
  border: none;
}

#cs-like-dis {
  display: flex;
  flex-direction: row;
}

/* ---------------------------------- replay comments ---------------------------------------------- */
#cv-Rcomment {
  display: flex;
  flex-direction: row;
  gap: 3%;
  margin-bottom: 2%;
  justify-content: right;
}

#cs-Rcard {
  /* padding: 1%; */
  /* background-color: #f5f5f5; */
  width: 79%;
  border: 0px;
}

@media screen and (min-width: 992px) {
  #card-noti {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  #flexCheckChecked {
    height: 30px;
    width: 30px;
    border-radius: 50px;
    margin-top: 15px;
    background-color: var(--primary-color);
  }

  #btnNotification1 {
    color: #ffffff;
    background-color: #00445e;
  }

  #btnNotification1:hover {
    background-color: var(--secondary-color);
  }

  #btnNotification11 {
    color: #000000;
    background-color: #edf2f8;
  }

  #btnNotification11:hover {
    color: #ffffff;
    background-color: var(--secondary-color);
  }

  #btnNewsnoti {
    width: 10%;
    color: #000000;
    background-color: #edf2f8;
  }

  #btnNewsnoti:hover {
    color: #ffffff;
    background-color: var(--secondary-color);
  }

  #btnNewsnoti1 {
    width: 10%;
    color: #ffffff;
    background-color: #00445e;
  }

  #btntrash {
    color: #c40000;
    font-size: 18px;
    /* border: 1px solid var(--primary-color); */
    /* background-color: rgba(230, 113, 92, 0.345); */
    /* width: 8rem; */
    /* height: 2.1rem; */
    /* padding: 1%; */
  }

  #btntrash:hover {
    border: 1px solid var(--secondary-color);
    background-color: var(--secondary-color);
    color: #ffffff;
  }

  .hide-laptop {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  #vps-secondry-images {
    height: 15rem !important;
  }

  .hide-mobile {
    display: none;
  }

  #btnNotification1 {
    width: 50%;
    color: #ffffff;
    background-color: var(--primary-color);
  }

  #btnNotification1:hover {
    background-color: var(--secondary-color);
  }

  #btnNewsnoti {
    width: 50%;
    color: #000000;
    background-color: #edf2f8;
  }

  #btnNewsnoti:hover {
    color: #ffffff;
    background-color: var(--secondary-color);
  }

  #card-noti {
    display: flex;
    flex-direction: column;
  }

  .iconTrash {
    width: 100%;
    margin: auto !important;
  }

  p.hide-laptop {
    margin-bottom: 0;
  }

  #btntrash:hover {
    border: 1px solid var(--secondary-color);
    background-color: var(--secondary-color);
    color: #ffffff;
  }
}

#submitbutton:hover {
  background-color: #fff;
  color: #168cb9;
  border: #168cb9 solid 1px;
}

@media screen and (min-width: 992px) {
  .ModalWrapper55 {
    height: 30rem;
    display: flex;
    flex-direction: row;
    border-radius: 20px;
    overflow: hidden;
  }

  .Background {
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: blur(5px);
  }

  #logo5 {
    width: 25%;
    height: auto;
  }

  .ModalImg5 {
    width: 100%;
    height: 30rem;
    border-radius: 20px 0 0 20px;
    object-fit: cover;
  }

  .logo-text5 {
    color: #ffffff;
    text-align: center;
    width: 50%;
    position: absolute;
    bottom: 3%;
  }

  .h {
    margin-top: 6.5rem !important;
    margin-bottom: 3rem !important;
  }

  .otp-field {
    margin: 11px;
    height: 50px;
    width: 50px;
    border: #000;
    border-radius: 5px;
    text-align: center;
    font-family: arimo;
    font-size: 1.2rem;
    background: #b1dde8;
  }

  .userInput {
    display: flex;
    justify-content: center;
  }

  .modal-content {
    width: 100%;
    background-color: #ffffff;
    border-radius: 0 20px 20px 0;
  }
}

@media screen and (max-width: 991px) {
  .otp-container {
    align-items: center;
    justify-content: center;
  }

  .otp-container input[type="password"] {
    min-width: 25px;
    min-height: 25px;
    text-align: center;
    margin-right: 20px;
  }

  #login_img5 {
    display: none;
  }

  .modal-content {
    border-radius: 20px;
  }

  .PhoneInput {
    display: flex;
    align-items: center;
    margin-top: 17%;
  }

  #resendbutton {
    color: var(--secondary-color);
    width: fit-content;
    align-items: center;
    justify-content: center;
    position: relative;
    left: 25%;
  }

  #resendbutton:hover {
    background-color: #fff;
    color: var(--primary-color);
    border: var(--primary-color) solid 1px;
  }
}

@media screen and (min-width: 992px) {
  .ModalWrapper44 {
    min-width: 680px;
    height: 36rem;
    display: flex;
    flex-direction: row;
    border-radius: 7px;
  }


  #logo4 {
    width: 25%;
    height: auto;
  }

  .logo-text4 {
    color: #000;
    text-align: center;
    width: 50%;
    position: absolute;
    bottom: 3%;
  }

  .ModalImg4 {
    width: 100%;
    height: 36rem;
    border-radius: 7px 0 0 7px;
    object-fit: cover;
  }
}

@media screen and (max-width: 991px) {
  #login_img4 {
    display: none;
  }

  #modal-content {
    border-radius: 20px;
  }
}

#rnv-card {
  border: none;
  margin-top: 1%;
}

#rnv-card-image {
  aspect-ratio: 1/1;
  object-fit: cover;
  position: relative;
}

#rnv-card-body {
  padding: 0%;
  height: 40%;
}

#rnv-btnCatagory {
  color: #ffffff;
  background: #000000a1;
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 10px !important;
}

#rnv-card-title {
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 600;
  color: black;
}

#btnrnvRead {
  color: var(--primary-color);
  background: #d1d1d1;
  border: none;
}

#rnv-card-date {
  font-size: large;
}

#rnv-logoCalendar {
  margin-bottom: 0.6%;
  margin-right: 1%;
}

@media (max-width: 1370px) {
  #rnv-card-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 2%;
  }

  #rnv-card-date {
    font-size: 14px;
  }
}

@media (max-width: 1250px) {
  #rnv-card-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 2%;
  }

  #rnv-card-date {
    font-size: 14px;
  }
}

@media (max-width: 1090px) {
  #rnv-card-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 2%;
  }

  #rnv-card-date {
    font-size: 12px;
  }
}

@media (max-width: 850px) {
  #rnv-btnCatagory {
    margin-top: 3%;
    color: #ffffff;
    background: var(--primary-color);
    width: fit-content;
  }
}

@media (max-width: 770px) {
  #rnv-card {
    width: 100%;
    height: 10rem;
    border: none;
    margin-top: 1%;
    flex-direction: row;
  }

  #rnv-card-image {
    max-width: 30%;
    height: 100%;
    object-fit: cover;
  }

  #rnv-card-body {
    padding: 1%;
    width: 70%;
    height: 100%;
  }
}

@media (max-width: 425px) {
  #rnv-card {
    width: 100%;
    height: 8rem;
    border: none;
    margin-top: 1%;
    flex-direction: row;
  }

  #rnv-card-image {
    max-width: 30%;
    height: 100%;
    object-fit: cover;
  }

  #rnv-card-body {
    padding: 1%;
    width: 70%;
    height: 100%;
  }

  #rnv-btnCatagory {
    margin-top: 3%;
    color: #ffffff;
    background: var(--primary-color);
    padding: 1%;
    font-size: 10px;
    width: fit-content;
  }

  #rnv-card-title {
    font-size: 12px;
    font-weight: 500;
    line-height: 1.2;
    margin-top: 2%;
  }

  #rnv-card-date {
    font-size: 10px;
  }
}

#forgot {
  font: "small-caption";
  color: black;
  /* display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding-top: 0; */
  cursor: pointer;
}

.fs-7 {
  font-size: 14px !important;
}
.fs-6 {
  font-size: 16px !important;
}
.fs-8 {
  font-size: 12px !important;
}
.fs-9 {
  font-size: 10px !important;
}
.fs-5 {
  font-size: 18px !important;
}

.model_login_popup .modal-title {
  font-size: 21px !important;
  font-weight: 700;
}

@media screen and (min-width: 992px) {
  .bordert {
    border-top: 1px solid #aaa;
    position: relative;
  }

  .bordert:after {
    content: "(OR)";
    position: absolute;
    top: -17px;
    left: 42%;
    margin: auto;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    width: 40px;
    height: 44px;
    border-radius: 10px;
  }

  .ModalContent5 {
    width: 56%;
  }

  #ModalWrapper22 {
    min-width: 680px;
    height: 39rem;
    display: flex;
    flex-direction: row;
    border-radius: 7px;
    overflow: hidden;
  }

  .logo-text3 {
    color: #000;
    text-align: center;
    width: 50%;
    position: absolute;
    bottom: 3%;
  }

  #logo3 {
    width: 25%;
    height: auto;
  }

  .ModalImg3 {
    width: 100%;
    height: 43.5rem;
    border-radius: 7px 0 0 7px;
    object-fit: cover;
  }

  [dir="rtl"] .ModalImg3 {
    border-radius: 0px 20px 20px 0px;
  }

  .my-2 {
    margin-top: -2.5rem !important;
  }

  .ModelHeader {
    position: relative;
    top: 10px;
    left: 20px;
    height: 32px;
    z-index: 10;
  }

  .form {
    position: relative;
    left: 20px;
    height: 32px;
    padding: 20px;
    z-index: 10;
  }

  .mx-5 {
    margin-right: 37rem !important;
    margin-left: 20rem !important;
  }

  #modal-content {
    width: 100%;
    background-color: #ffffff;
    border-radius: 0 20px 20px 0;
  }

  [dir="rtl"] #modal-content {
    border-radius: 20px 0px 0px 20px;
  }

  #loginbutton2 {
    color: #ffffff;
    background: #168cb9;
  }

  #loginbutton2:hover {
    background-color: #fff;
    color: #168cb9;
    border: #168cb9 solid 1px;
  }

  .error-msg {
    font-size: 12px;
    color: rgb(250, 50, 50);
    text-align: justify;
    margin-left: 0rem;
  }

  .imageInput {
    height: 8rem;
    width: 100%;
    object-fit: cover;
  }

  /* } */
  #textwithimage {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 5%;
    max-height: 10%;
    margin-top: 5%;
  }
}

.password-icon {
  position: relative;
  bottom: 36px;
  float: right;
  right: 20px;
  cursor: pointer;
}

.Cpassword-icon {
  position: relative;
  bottom: 38px;
  float: right;
  right: 20px;
  cursor: pointer;
}

#modal-content {
  border-radius: 20px;
}

#loginbutton2 {
  color: #ffffff;
  background: #168cb9;
  font-size: 13px;
  padding: 9px 37px;
}

@media screen and (max-width: 1199px) {
  #login_img3 {
    display: none;
  }
}

#rbn-main {
  background-color: #ffffff;
  border-radius: 5px;
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 13px;
}

.rbn-image {
  width: 100%;
  height: 84px;
  border-radius: 9px !important;
}

body .text-dark {
  color: #000 !important;
}

#rbn-card {
  /* object-fit: cover;
  height: 6rem;
  width: 108px; */
  /* min-width: 30%; */
  /* max-width: 30%; */
  /* border-radius: 5px; */
  border: 0px !important;
}

#rbn-image {
  object-fit: cover;
  height: 6rem;
  width: 30%;
  min-width: 30%;
  max-width: 30%;
  border-radius: 5px;
}

#rbn-card-body {
  width: 70%;
  padding-left: 2%;
}

#btnrbnCatagory {
  color: #ffffff;
  background: #168cb9;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 3%;
  /* margin-left: 3%; */
  font-size: 12px;
}

/* #rbn-card-text {
  margin: 3%;
} */
#RNews-main {
  border-radius: 5px;
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px;
}

#RNews-cat-nav {
  padding: 10px 10px 0px 10px
}

#RNews-card {
  /* width: 96%; */
  height: 6rem;
  /* margin-left: 3%; */
  /* margin-right: 3%; */
  margin-top: 2%;
  margin-bottom: 1%;
  /* background-color: #ffffff; */
  overflow: hidden;
  border: none;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  border-radius: 5px;
  height: -moz-fit-content;
  height: fit-content;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 13px;
}

.theme_card {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border-radius: 10px;
}

#RNews-image {
  object-fit: cover;
  height: 100px;
  width: 100%;
  /* min-width: 30%; */
  /* max-width: 30%; */
  border-radius: 9px;
}

#RNews-card-body {
  width: 70%;
  padding-left: 2%;
}

[dir="rtl"] #RNews-card-body {
  padding-right: 2%;
  padding-left: 0;
}

#btnRNewsCatagory {
  color: #ffffff;
  background: #168cb9;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 2%;
  font-size: 12px !important;
}
#btnRNewsCatagory:hover {
  color: #168cb9;
  background: #fff;
  border: 1px solid #168cb9 !important;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 2%;
  font-size: 12px;
}

#RNews-card-text {
  margin-top: 6px;
  font-size: 14px !important;
  color: black !important;
  font-weight: 600;
}

#spinner {
  position: relative;
  left: 50%;
  padding-top: 10%;
}

#ts-card {
  border: none;
}

#ts-card-image {
  width: 100%;
  aspect-ratio: 1/0.8;
  object-fit: cover;
  position: relative;
}

#ts-card-body {
  padding: 0%;
  /* height: 40%; */
}

#ts-btnCatagory {
  color: #ffffff;
  background: var(--primary-color);
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 10px;
}

#ts-card-title {
  margin-top: 2%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

#ts-card-date {
  font-size: large;
}

#ts-logoCalendar {
  margin-bottom: 0.6%;
  margin-right: 1%;
}

#vnv-card {
  width: 100%;
  border: none;
  background-color: #ffffff;
}

#vnv-card-image {
  aspect-ratio: 1/1;
  position: relative;
  object-fit: cover;
  border-radius: 9px;
}

.home_banner {
  width: 100%;
  height: 100%;
  max-height: 470px;
  border-radius: 10px;
  min-height: 227px;
  object-fit: cover;
}

#vnv-card-body {
  padding: 1%;
}

#vnv-btnCatagory {
  margin-top: 3%;
  color: #ffffff;
  background: var(--primary-color);
}

#vnv-card-title {
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 600;
  color: #000000;
}

#btnvnvRead {
  color: var(--primary-color);
  background: #d1d1d1;
  border: none;
}

#vnv-card-date {
  font-size: large;
}

#vnv-logoCalendar {
  margin-bottom: 0.6%;
  margin-right: 1%;
}

#vnv-btnVideo {
  float: inline-end;
  color: #ffffff;
  background: #fff;
  border-radius: 50%;
  display: inline-flex;
  position: absolute;
  right: 5%;
  top: 5%;
}

#vnv-btnVideo:hover {
  box-shadow: 0 0 1px 8px rgba(255, 255, 255, 0.4),
    0 0 1px 16px rgba(255, 255, 255, 0.1);
}

#vnv-btnVideo-logo {
  margin-left: 10%;
}

#wcard-footer {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 33%;
  width: 100%;
  background-color: #ffffff49;
  display: flex;
  flex-direction: row;
  gap: 2.5%;
  justify-content: center;
  padding-top: 4%;
}

.cs-main h2 {
  margin-left: 0px;
  color: #000;
}

#cs-main textarea {
  padding-left: 15px;
  padding-top: 12px;
  border: 1.5px solid #d0d0d0;
  border-radius: 10px;
}

#B_NV-main #vps-btnVideo,
#nv-main #vps-btnVideo {
  color: #ffffff;
  background: #fff;
  border-radius: 50%;
  display: inline-flex;
  position: absolute;
  right: 44%;
  top: 41%;
}

.PhoneInputCountry {
  width: 70px;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: var(--primary-color) !important;
}

/* loader  */
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}

.loader:before {
  animation: ball1 1s infinite;
  background-color: #fff;
  box-shadow: 30px 0 0 var(--loader-color);
  margin-bottom: 10px;
}

.loader:after {
  animation: ball2 1s infinite;
  background-color: var(--loader-color);
  box-shadow: 30px 0 0 #fff;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg) scale(0.8);
  }

  50% {
    transform: rotate(360deg) scale(1.2);
  }

  100% {
    transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 var(--loader-color);
  }

  50% {
    box-shadow: 0 0 0 var(--loader-color);
    margin-bottom: 0;
    transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 var(--loader-color);
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #fff;
  }

  50% {
    box-shadow: 0 0 0 #fff;
    margin-top: -20px;
    transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #fff;
    margin-top: 0;
  }
}

.link-color.active {
  color: var(--primary-color) !important;
}

.headermodal .btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.otp-container input {
  width: 44px !important;
  height: 44px !important;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-bottom: 1px solid;
}

.ModalWrapper {
  display: flex;
}

.bg-copyright {
  background-color: #000000 !important;
}

.social_media svg {
  margin-right: 5px;
}

.terms_privacy a {
  color: #969696 !important;
  margin-bottom: 0px !important;
}

#footer .terms_privacy a:hover {
  color: white !important;
}

.social_media a {
  margin-bottom: 0px !important;
}

.social_media_link a {
  width: 33px !important;
  height: 33px !important;
  border-radius: 6px;
  background: black !important;
  border: 1px solid black;
  margin-right: 8px;
  display: flex !important;
  align-items: center;
  padding: 0px;
  justify-content: center;
}

.social_media_link a:hover {
  background: #168cb9 !important;
  border: 1px solid #168cb9;
}

.social_media_link a i {
  font-size: 14px;
}

.border_strong_head {
  border-right: 4px solid #168cb9;
  height: 30px;
}

.card_theme {
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 12px;
  /* height: 100%; */
}

.position_crd_vedio {
  position: absolute;
  right: 0%;
  top: 0%;
  left: 0px;
  width: 100%;
  height: 100%;
}

.one_line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1 !important;
  overflow: hidden;
}

#hns-card-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.two_line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2 !important;
  overflow: hidden;
}

.three_line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3 !important;
  overflow: hidden;
}

.four_line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4 !important;
  overflow: hidden;
}

/* .fit_text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ;
  overflow: hidden;
} */

.five_line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5 !important;
  overflow: hidden;
}

.six_line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6 !important;
  overflow: hidden;
}

.seven_line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 7 !important;
  overflow: hidden;
}

.eight_line {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8 !important;
  overflow: hidden;
}

.arrow_icon {
  width: 20px;
  height: 20px;
  object-fit: cover;
}

.fa.fa-sign-in:before {
  font-size: 11px;
}

#vps-Viewmore:hover {
  background-color: #cce4ed;
}

p:last-child {
  margin-bottom: 0px !important;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
  height: 6px !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #096a8f;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.overlay_home_image {
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 470px;
  left: 0;
  top: 0;
  display: flex;
  align-items: end;
  justify-content: left;
  background: #00000054;
  border-radius: 10px;
}

.icon_youtubr_overlay {
  width: 35px;
  height: 35px;
  background: red;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 50%;
}

.ul_home .slick-slide {
  opacity: 0;
  animation: fade-in 0.3s ease-in forwards;
}

.ul_home .slick-slide:nth-child(1) {
  animation-delay: 0.2s;
}

.ul_home .slick-slide:nth-child(2) {
  animation-delay: 0.4s;
}

.ul_home .slick-slide:nth-child(3) {
  animation-delay: 0.6s;
}

.ul_home .slick-slide:nth-child(4) {
  animation-delay: 0.8s;
}

.ul_home .slick-slide:nth-child(5) {
  animation-delay: 1.0s;
}

.ul_home .slick-slide:nth-child(6) {
  animation-delay: 1.2s;
}

.ul_home .slick-slide:nth-child(7) {
  animation-delay: 1.4s;
}

.ul_home .slick-slide:nth-child(8) {
  animation-delay: 1.8s;
}

.ul_home .slick-slide:nth-child(9) {
  animation-delay: 2.0s;
}

.ul_home .slick-slide:nth-child(10) {
  animation-delay: 2.2s;
}

.ul_home .slick-slide:nth-child(11) {
  animation-delay: 2.4s;
}

.ul_home .slick-slide:nth-child(12) {
  animation-delay: 2.6s;
}

.ul_home .slick-slide:nth-child(13) {
  animation-delay: 2.8s;
}

.ul_home .slick-slide:nth-child(14) {
  animation-delay: 3.0s;
}

@keyframes fade-in {
  from {
    transform: translateX(-2em);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.btn {
  font-size: 13px;
}

.banner_home img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  max-height: 205px;
}

#cv-card-image {
  max-width: 30%;
  height: 100%;
  object-fit: cover;
}

#cv-card {
  width: 100%;
  border: none;
  margin-top: 1%;
  flex-direction: row;
}

#cv-card-body {
  padding: 1%;
  width: 70%;
  height: 100%;
}

#cv-card-date {
  width: 112px;
  color: gray;
  font-weight: 400;
  font-size: 12px !important;
}

#vps-Viewmore:hover {}

.scrol_listinf {
  max-height: 700px;
  overflow-y: scroll;
  overflow-x: clip;
  padding: 5px;
  scroll-behavior: smooth;
}

.scrol_listinf::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
  height: 6px !important;
}

.scrol_listinf::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(196, 196, 196, 0.3);
  background-color: #ffffff;
}

.scrol_listinf::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  background-color: #ffffff;
}

#cv-card-date svg {
  animation: animName 4s linear infinite;
}

@keyframes animName {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.modal-header {
  border: 0 !important;
}

.form_login_bg label {
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
  font-size: 14px;
}

.form_login_bg .form-control {
  font-size: 13px;
}

#loginbutton {
  font-size: 13px;
  width: auto !important;
  padding: 10px 25px;
}

.register_popup_register .modal-title {
  font-size: 21px !important;
  font-weight: 700;
}

.register_popup_register label {
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
  font-size: 14px;
}

.register_popup_register .form-control {
  font-size: 13px;
}

.forgot_paword_popup .modal-title {
  font-size: 21px !important;
  font-weight: 700;
}

.breadcrumb-item+.breadcrumb-item {
  padding-right: 0px !important;
}

.breadcrumb-item+.breadcrumb-item::before {
  padding-left: 10px;
}

#main_contact_us h1 {
  font-weight: 600;
  color: #000000;
  margin-bottom: 30px !important;
}

#main_contact_us h4 {
  font-weight: 900;
  color: #00445e;
  margin-top: 30px;
}

#main_contact_us p {
  margin-bottom: 0px;
  color: #000000;
}

#main_contact_us h4:first-child {
  margin-top: 0px !important;
}

.map-ifram {
  border-radius: 10px;
}

.banner_home img {
  transition-duration: 2s;
  margin: 0 auto;
  display: block;
}

.banner_home img:hover {
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
}

.nav_fixed_bg {
  position: sticky;
}

.CustomBackToTopButton {
  width: 40px !important;
  height: 40px !important;
  right: 20px !important;
  background: #00445e !important;
  animation: bounce 4s infinite;
  opacity: 1;
}

.CustomBackToTopButton:hover {

  opacity: 0.5;
}
#about-us h4{
  margin-bottom: 15px !important;
}
@-webkit-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-20px);
  }

  60% {
    -webkit-transform: translateY(-15px);
  }
}

@-moz-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-20px);
  }

  60% {
    -moz-transform: translateY(-15px);
  }
}

@-o-keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    -o-transform: translateY(0);
  }

  40% {
    -o-transform: translateY(-20px);
  }

  60% {
    -o-transform: translateY(-15px);
  }
}

@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-20px);
  }

  60% {
    transform: translateY(-15px);
  }
}


/* .zoom{
border-radius: 10px 10px 0px 0px;

oom img {
transition-duration: 2s;
margin: 0 auto;
display: block;


oom img:hover {
transform: scale(1.05);
-webkit-transform: scale(1.05);
-moz-transform: scale(1.05);
*/

/* @media (min-width:1200px) {

  .js-scroll {

    transition: opacity 500ms;
  }

  .js-scroll.scrolled {
    opacity: 1;
  }

  .scrolled.fade-in {
    animation: fade-in 1s ease-in-out both;
  }

  .scrolled.fade-in-bottom {
    animation: fade-in-bottom 1s ease-in-out both;
  }

  .scrolled.slide-left {
    animation: slide-in-left 1s ease-in-out both;
  }

  .scrolled.slide-right {
    animation: slide-in-right 1s ease-in-out both;
  }

  @keyframes slide-in-left {
    0% {
      -webkit-transform: translateX(-50px);
      transform: translateX(-50px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slide-in-right {
    0% {
      -webkit-transform: translateX(50px);
      transform: translateX(50px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
      opacity: 0;
    }

    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
} */


.offcanvas-body ul {
  padding: 0px !important;
}

.offcanvas-body ul.sub-menu {
  padding-left: 10px !important;
}

.sticky {
  position: sticky !important;
  top: 0;
  width: 100%;
  /* transition: all 0.5s ease; */
  /* animation: smoothScroll 1s forwards; */
  z-index: 9999;
  /* background-color: white !important; */
}

#cn-main {
  padding: 7px 0px 7px 0px !important;
}
.category-wrapper{
  /* padding: 10px 0px 7px 0px !important; */
}
/* .category-wrapper:hover{
  background-color: white;
} */
.navbar {
  padding: 0px !important;
}

@keyframes smoothScroll {
  0% {
    transform: translateY(-142px);
  }

  100% {
    transform: translateY(0px);
  }
}

#bns-logo-col {
  position: relative;
  overflow: hidden;
  background: linear-gradient(90deg, #000, #fff, #000);
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 3s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

@keyframes animate {
  0% {
    background-position: -500%;
  }

  100% {
    background-position: 500%;
  }
}

.txt-dec text {
  font-family: 'Noto Sans Tamil', sans-serif !important;
  animation: stroke 5s infinite alternate;
  stroke-width: 2;
  stroke: #ffffff;
  font-size: 130%;
  /* font-size: 160px; */
}

@keyframes stroke {
  0% {
    fill: rgba(72, 138, 20, 0);
    stroke: rgb(255, 255, 255);
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 2;
  }

  70% {
    fill: rgba(72, 138, 20, 0);
    stroke: rgb(255, 255, 255);
  }

  80% {
    fill: rgba(72, 138, 20, 0);
    stroke: rgb(255, 255, 255);
    stroke-width: 3;
  }

  100% {
    fill: rgb(255, 255, 255);
    stroke: rgba(54, 95, 160, 0);
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke-width: 0;
  }
}

#hns-center-col #Link-all:last-child #hns-card {
  margin-bottom: 0px !important;
}

#btnbnsViewAll:hover {
  background: #c6efff !important;
}

#bns-card-set a:hover {
  color: var(--primary-color);
}

.custome_titile {
  color: #000;
  font-weight: 600;
}

.img_sritual_ju {
  aspect-ratio: 1/0.7;
  object-fit: cover;
  border-radius: 10px;
  object-fit: cover;
  border-radius: 10px;
}

.img_sritual_ju1 {
  aspect-ratio: 1/0.8;
  object-fit: cover;
  border-radius: 10px;
  object-fit: cover;
  border-radius: 10px;
}

.img_sritual_round {
  width: 85px;
  height: 85px;
  border-radius: 50%;
}

.card-spritual-crd {
  border-radius: 15px;
  background-color: #f1f1f1;
  padding: 15px;
}

.visf_colrty span {
  color: #168cb9;
  font-size: 14px;
}

.img_sidebar {
  object-fit: cover;
  border-radius: 10px;
  width: 100%;
  aspect-ratio: 1/0.4;
}

/* .text-gray {
  color: gray;
} */
.text-gray {
  color: #3f3f3f;
}
.text-san-1 {
  color: #df5151;
  font-size: 13px;
  font-weight: 600;
}

.text-san-2 {
  font-size: 13px;
  color: gray !important;
}

.fs-6px {
  font-size: 6px;
}

.bg_marqyre {
  display: flex;
  align-items: center;
  background-color: #efefef;
  color: black;
  border-radius: 10px;
  font-family: 'Noto Sans Tamil', sans-serif;
  padding: 0px 10px 0px 0px;
}



.txt_anrer {
  width: 212px;
  min-width: 212px;
  font-size: 13px;
  background: #168cb9;
  color: white;
  border-radius: 5px;
  padding: 7px 9px;
  font-weight: 600;
  display: flex;
  align-items: center;
  font-family: 'Noto Sans Tamil', sans-serif;
}

marquee {
  font-size: 13px;
  color: #000000;
  font-weight: 600;
}

.text-theme {
  color: #000 !important;
}

.img_nv {
  width: 26px;
  height: 26px;
  object-fit: cover;
}

.nav-next {
  background: transparent;
  border: 1px solid #369bc1;
  border-radius: 10px;
  padding: 4px;
  width: 40px;
  height: 40px;
}

.nav-review {
  background: transparent;
  border: 1px solid #369bc1;
  border-radius: 10px;
  padding: 4px;
  margin-right: 10px;
  width: 40px;
  height: 40px;
}

.nav-review:hover {
  background: #369bc1;
}

.nav-next:hover {
  background: #369bc1;

}

.nav-review:hover .pre1 {
  display: block;
}

.nav-review:hover .pre2 {
  display: none;
}

.nav-next:hover .next1 {
  display: block;
}

.nav-next:hover .next2 {
  display: none;
}

.pre1 {
  display: none;
}

.next1 {
  display: none;
}

.nav_position {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0px;
  right: 14px;
}

.banner_add {
  /* width: 46%; */
  width: 75%;
  /* max-height: 63px !important; */
  object-fit: cover;
}

@keyframes homeimport {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ul_home .headre_icon {
  -webkit-animation: moveit 2.5s infinite;
  animation: moveit 2.5s infinite;
  position: relative;
}

@-webkit-keyframes moveit {
  from {
    top: 0px;
    opacity: 0.1;
  }

  25% {
    top: 5px;
    opacity: 1;
  }

  50% {
    top: 4px;
    opacity: 1;
  }

  75% {
    top: 5px;
    opacity: 1;
  }

  to {
    top: 0px;
    opacity: 0.1;
  }
}

@keyframes moveit {
  from {
    top: 0px;
    opacity: 0.1;
  }

  25% {
    top: 5px;
    opacity: 1;
  }

  50% {
    top: 4px;
    opacity: 1;
  }

  75% {
    top: 5px;
    opacity: 1;
  }

  to {
    top: 0px;
    opacity: 0.1;
  }
}

.img_icon_home {

  width: 21px;
  height: 21px;
  object-fit: cover;
  margin-right: 5px;
}

.bg_marqyre .img_icon_home {
  animation: homeimport 2s linear infinite;
}

@media (max-width: 767px) {
  .txt_anrer {
    width: 40px !important;
    min-width: 40px !important;
  }
}

.footer_add {
  width: 100%;
  max-height: 250px;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.footer_row h5 {
  color: white;
  margin-bottom: 15px;
}

#footer ul li {
  color: #969696;
  font-family: 'Noto Sans Tamil', sans-serif;
  font-size: 13px;
  margin-bottom: 2px;
}

#footer ul li::marker {
  color: #969696;
}

#footer ul {
  padding-left: 0px !important;
}

.accordian_footer {
  font-family: 'Noto Sans Tamil', sans-serif;
}

.accordion__heading {
  font-size: 14px;
}

.accordion__button::after {
  content: "\f0d7";
  font-family: var(--fa-style-family, "Font Awesome 6 Free");
  font-weight: var(--fa-style, 900);
  position: absolute;
  right: 0px;
  font-size: 12px;
}

.accordion__button {
  position: relative;
  margin-bottom: 15px;
}

.accordion__item {
  margin-bottom: 15px;
}

#rns-images {
  aspect-ratio: 1/0.7;
  position: relative;
  /* height: 208px; */
  border-radius: 10px;
  object-fit: cover;
  transition: 0.3s;
}

@media (min-width:1590px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1480px !important;
  }
}

.card_theme #vps-btnVideo {
  background: #ff0000;
  color: #ff0000;
}

.card_theme #vps-btnVideo:hover {
  box-shadow: 0 0 1px 8px rgb(255 0 0 / 45%), 0 0 1px 16px rgb(255 0 0 / 28%);
}

.add_img {
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
  min-height: 100px;
}

.add_without_border {
  width: 100%;
  object-fit: cover;
}

.next_page {
  background-color: #ececec;
  width: 174px;
  border-radius: 10px;
  padding: 8px 10px;
  color: black;
  font-size: 13px;
  border: none;
}



.read_more_carefsd {
  color: #168cb9;
  font-size: 13px;
  text-decoration: underline !important;
}

.detail-pg_images {
  width: 100%;
  aspect-ratio: 1/0.6;
  object-fit: cover;
  border-radius: 10px;
}

.text-theme-light {
  color: #168cb9;
}

.footer-star {
  line-height: 0px;
  margin-bottom: -3px;
  margin-right: 3px;
}

.btn {
  font-family: 'Noto Sans Tamil', sans-serif !important;
}

#footer ul li:hover {
  color: white !important;
  cursor: pointer;
}

.nav_offcannav a {
  color: #000;
  font-size: 13px;
  margin-bottom: 10px;
}

.marquresfgscz {
  display: flex;
  overflow: hidden;
  width: 100%;
}

/* @keyframes slowMove {
  0% { transform: translateX(0); }
  50% { transform: translateX(50px); } 
  100% { transform: translateX(0); }
} */


marquee div {
  animation: slowMove 16s infinite !important;
}

marquee {
  min-width: 100%;
  scroll-behavior: smooth;
}

.cursor-pointer {
  cursor: pointer;
}

.navbar-collapse {
  width: 100%;
}

.custome_scroll {
  width: 100%;
  /* overflow: auto; */
  overflow-y: hidden !important;
  padding-top: 4px !important; 
  /* z-index: 99999999999 !important; */
}


.custome_scroll::-webkit-scrollbar {
  width: 5px;
  background-color: #00445e;
  height: 6px !important; 
  display: none !important;
}

.custome_scroll::-webkit-scrollbar-thumb {
  border-radius: 0px !important;
  -webkit-box-shadow: none !important;
  background-color: #00445e;
}
.custome_scroll::-webkit-scrollbar-track{
  -webkit-box-shadow: none !important;
  border-radius: 0px;
  background-color: #00445e;
}

.twit-scrol iframe::-webkit-scrollbar {
 width: 5px;
  background-color: transparent !important;
  height: 6px !important; 
  /* display: none !important; */
}

.twit-scrol iframe::-webkit-scrollbar-thumb {
  border-radius: 0px !important;
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}
.twit-scrol iframe::-webkit-scrollbar-track{
  -webkit-box-shadow: none !important;
  border-radius: 0px;
  background-color: transparent !important;
}

.twit-scrol iframe {
  overflow-y: scroll !important;
}

.sjiosh {
  border: 0px !important;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.sjiosh .popover-header {
  padding: 8px 4px 4px 5px;
  background: transparent;
  font-size: 14px !important;
  color: black;
  font-weight: 600;
  border: 0px;
}

#noti_profile {
  object-fit: cover;
  min-width: 132px;
  width: 132px;
  height: 132px;
  border-radius: 50%;
}

#card-noti {
  position: relative;
}

.iconTrash {
  position: absolute;
  right: 0px;
  width: auto;
}

#btnNotification1 {
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#btnNewsnoti {
  font-size: 13px;
}

#btnNotification11 {
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#btnNewsnoti1 {
  font-size: 13px;
}


@media (min-width: 1590px) {
  .col-xl-custome {
    flex: 0 0 auto;
    width: auto !important;
  }
}

.next_page[disabled] {
  display: none !important;
}

.ul_home .slick-track {
  max-height: 422px !important;
}

.h-48px {
  height: 48px;
}

.react-confirm-alert-body {
  width: 100% !important;
  max-width: 400px;
}

.react-confirm-alert-overlay {
  background: rgb(42 42 42 / 90%) !important;
}




.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
  z-index: 898;
}

.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  position: relative;
  transition: all 1s ease-in-out;
}

.popup #social_platforms {
  margin-right: 10px;
}

.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}

.popup .content {
  max-height: 30%;
  overflow: auto;
}

@media screen and (max-width: 700px) {
  .box {
    width: 70%;
  }

  .popup {
    width: 70%;
  }
}

.like-button {
  color: #cd0d1e !important;
}

@media screen and (min-width:1400px) {
  .vdio_heiht {
    height: 374px !important;
  }

  .custome_col_2 {
    flex: 0 0 auto;
    width: 16.66666667% !important;
  }
}

@media screen and (min-width:1200px) and (max-width:1399px) {
  .vdio_heiht {
    height: 374px !important;
  }
}

@media screen and (min-width:999px) and (max-width:1199px) {
  .vdio_heiht {
    height: 374px !important;
  }
}
@media screen and (max-width:500px) {
  .vdio_heiht {
    height: 300px !important;
  }
}

.spectasdc {
  top: 17px !important;
}


@media screen and (max-width: 767px) {
  body .left-weather {
    margin-right: 3px !important;
    min-width: 88px !important;
    font-size: 10px !important;
  }

  .left-weather p {
    font-size: 9px !important;
  }

  body .banner_add {
    /* width: 36%; */
    width: 57%;
    padding: 0px 4px !important;
  }

  .weather_icon {
    margin-right: 3px !important;
    height: 29px !important;
  }

  .hamburger-manu .btn {
    padding: 0px !important;
  }
}

a {
  color: #000;
}

.facebook_jhs iframe {
  width: 100% !important;
}

@media screen and (min-width:1200px) and (max-width:1500px) {
  #vps-secondry-images {
    height: 17rem !important;
  }
}

@media screen and (min-width:576px) and (max-width:767px) {
  #hns-main-image {
    height: 14.6rem !important;
  }
}


.modal-content {
  border: none !important;
  border-radius: 20px !important;
}

.groupformcontrol .input-group {
  border: 1px solid #dee2e6 !important;
  border-radius: 6px !important;
  padding: 4px;
}

.groupformcontrol .input-group-text {
  border: none !important;
  background-color: transparent !important;
  padding: 8px 0px 8px 8px;
  color: #a6a8aa;
}

.groupformcontrol .form-control {
  border: none !important;
  font-size: 13px;
}

body :-internal-autofill-selected {
  background-color: transparent !important;
}

.password-icon-1 {
  color: #a6a8aa !important;
}

.PhoneInput {
  border: 1px solid #dddddd;
  border-radius: 6px;
}

.otp-container input:last-child {
  margin-right: 0px !important;
}

.otp-container input:focus-visible {
  border-top: 0px !important;
  border-right: 0px !important;
  border-left: 0px !important;
  border-bottom: 1px solid !important;
}

.x5yr21d {
  height: 100px !important;
}

/* .InstagramEmbed{
    max-height: 390px;
  }
  .FacebookEmbed{
    max-height: 390px;
  }
  .TwitterEmbed{
    max-height: 390px;
  }
  .YouTube{
    max-height: 390px;
  } */

.default-heifht {
  min-height: 700px;
  max-width:100% !important;
}

/* #catNav-links p{
    min-width: 75px;
    width: auto !important;
  } */

iframe body {
  overflow: hidden !important;
}

.chrome {
  overflow: hidden !important;
}

.iframe-without-scroll {
  border: 1px solid #cfd9de;
  border-radius: 9px;
  /* width: 100% !important; */
}

#logo1 {
  width: 25%;
  height: auto;
}

#logo2 {
  width: 25%;
  height: auto;
}

#logo3 {
  width: 25%;
  height: auto;
}

#logo4 {
  width: 25%;
  height: auto;
}

#logo5 {
  width: 25%;
  height: auto;
}

#logo6 {
  width: 25%;
  height: auto;
}

/* .bordert:after {
  content: "(OR)";
  position: absolute;
  top: -17px;
  left: 42%;
  margin: auto;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  width: 40px;
  height: 44px;
  border-radius: 10px;
} */

.bg-const {
  background-color: #eef7fa;
  padding-top: 30px;
}

.Toastify__toast-container {
  z-index: 10000 !important;
}

.twiter{

}

.insatgram{
}

.facebook{

}
.h-500px {
  height: 500px !important;
}
._2p3a {
  width: 100% !important;
}
._2lqh {
  width: 100% !important;
}
.twitter-timeline-rendered {
  height: 530px !important;
  width: 320px !important;
  overflow: auto !important;
}
.twitter-timeline-rendered::-webkit-scrollbar {
  display: none !important;
}
.offcanvas.offcanvas-start.show {
  visibility: visible;
  width: 300px;
  margin: 20px;
  border-radius: 15px;
}
.offcanvas.offcanvas-start {
  max-width: 300px !important;
  margin: 20px !important;
  border-radius: 15px;
}
/* .offcanvas.offcanvas-end.show {
  visibility: visible;
  width: 300px;
  margin: 20px;
  border-radius: 15px;
}
.offcanvas.offcanvas-end {
  max-width: 300px !important;
  margin: 20px !important;
  border-radius: 15px;
} */
.sdfjkjfjh {
  /* min-height: 2000px !important; */
  /* width: 100% !important; */
}
#catNav-links:hover{
  color: #288bb1;
}
a:hover{
  color: #288bb1 !important;
}
#category-wrapper:hover p{
  color: #000 !important;
}

/* .category-wrapper:hover p{
  color: #000 !important;
} */
.bs-popover-bottom{
  inset: 0px -20px auto auto !important;
}
#catNav-links.active p {
  /* color: #168cb9;  */
  border-bottom: 1px solid white !important;
}
#catNav-links p{
  font-weight: 600 !important;
}
@media (min-width: 576px){
  .medicalhdo .modal-dialog {
    max-width: 601px !important;
}
}

@media (min-width: 375px){
  .offcanvas.offcanvas-start.show{
    width: 340px;
  }
  .offcanvas.offcanvas-start{
    max-width: 340px !important;
  }
}

@media (max-width: 575px){
  .medicalhdo .modal-dialog {
    max-width: 294px !important;
}
}
:focus-visible {
  outline: transparent auto 1px !important;
}
.form-control:focus{
  box-shadow: none !important;
}

.react-confirm-alert-button-group > button{
  background-color: #00445e !important;
}

.offcanvas{
  z-index: 99999 !important;
}

#sticky-header1{
  /* background-color: white; */
}
.category-wrapper #catNav-links{
display: flex;
}
.category-wrapper #catCanvas-links{
display: flex;
}
.category-wrapper .catCanvas-links{
display: flex;
}

.category-wrapper{
  /* position: relative; */
}



.slider {
  position: absolute;
  height: 2px; /* Adjust as needed */
  background-color: blue; /* Adjust to your preferred color */
  transition: left 0.3s ease; /* Add a smooth transition effect */
  z-index: 1; /* Ensure it's above the category links */
}
.ytp-cued-thumbnail-overlay-image{
  background-size: contain !important;
}

.pagination li{
  background: #168cb9;
  border: 1px solid #168cb9;
  margin: 1px;
  padding: 1px 10px;
  border-radius: 5px;
  color: white;
  font-size: 14px;
}
.pagination li a{
  color: white;
}
.pagination li a:hover{
  color: white !important;
}
.pagination{
  padding: 10px !important;
}

/* .pagination .previous a{
 display: none;
} */
/* .pagination .previous a::before{
  content: "\f104";
  font-family: var(--fa-style-family,"Font Awesome 6 Free");
  font-weight: var(--fa-style,900);
}
.pagination .next a::before{
  content: "\f105";
  font-family: var(--fa-style-family,"Font Awesome 6 Free");
  font-weight: var(--fa-style,900);
} */
/* .pagination .next a{
  display: none;
 } */

.pagination .active{
  background: #00445e !important;
  border: 1px solid #00445e !important;
}

#nv-body #cv-card-date{
  width: 137px;
}

/* .subcategory-dropdown p:hover{
  color: #000 !important;
} */

.btn_slide{
  background-color: transparent;
  border: none;
  color: white;
  margin: 0px 10px;
}

.modal-backdrop {
  background-color: #0000004f !important;
  opacity: 1 !important;
}
.modal{
  z-index: 1000000 !important;
}
#nv-body .add_img{
  max-height: 240px;
}

.offcanvas-body .accordion__button::after{
  top: 0px !important;
  padding: 10px !important;
  padding-top: 0 !important;
}

.offcanvas-body .accordion__button{
  margin-bottom: 0px !important;
}

.offcanvas-body .category-wrapper #catNav-links{
  margin-bottom: 15px !important;
  margin-right: 50px !important;
  color: #00445e;
}
.offcanvas-body #catNav-links{
  margin-left: 0px !important;
  color: #00445e;
}
.offcanvas-body .accordion__panel {
  margin-left: 10px !important;
}
.offcanvas-body .accordion__panel p{
  color: #000 ;
  margin-bottom: 10px;
  margin-bottom: 8px !important;
}
.offcanvas-body .accordion__panel p:hover{
  color: #00445e !important;
}

.offcanvas-body #catCanvas-links:hover{
  color: #000 !important;
}
.offcanvas-body .catCanvas-links:hover{
  color: #000 !important;
}
.offcanvas-body::-webkit-scrollbar {
  display: none !important;
}

.h-40px{
  height: 40px !important;
}


/* .controls a:first-child p{
padding-top: 20px;
}

.controls a:last-child p{
  padding-bottom: 20px;
  } */



  a:hover{
    cursor: pointer !important;
  }

  /* Full width dropdown */

  /* .subcategory-dropdown{
    position: absolute;
    left: 0px;
    background-color: white;
    border-radius: 8px;
    z-index: 99999999999999;
    max-width: 100% !important;
    min-width: 100% !important;
    width: 100% !important;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: opacity 0.2s ease;
  }
  
  .subcategory-dropdown p{
    color: #000;
    padding: 5px 10px;
  }
  
  .custome_dropdown{
  position: fixed;
  display: block;
  left: 0px;
  z-index: 99999999999999;
  max-width: 100% !important;
  min-width: 100% !important;
  width: 100% !important;
  }
  
  @media (min-width:1201px) {
    .submenu-filled{
      max-height: 500px;
      overflow-y: auto;
      }
      
  }
  
  .controls{
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 10px !important;
    margin-top: 11px !important;
  } */




  .submenu-filled{
    padding: 20px 10px;
  }

  .fs-12{
    font-size: 10px !important;
  }
  .fs-10{
    font-size: 10px !important;
    color: #00445e;
  }
  .bg_marqyre #Link-all:hover{
    color: #00445e !important;
  }

  /* sm dropdown */

  .subcategory-dropdown{
    /* position: absolute; */
    top: 100;
    background-color: white;
    border-radius: 8px;
    z-index: 99999999999999 !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  
  .subcategory-dropdown p{
    color: #00445e;
    padding: 5px 10px;
  }
  
  .custome_dropdown{
  /* position: relative !important; */
  top: 0 !important;
  left: 0 !important;
  display: block;
  z-index: 99999999999999;
  }
  
  @media (min-width:1201px) {
    .submenu-filled{
      max-height: 500px;
      overflow-y: auto;
      }
      
  }
  
  .controls{
    /* background-color: white; */
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    /* margin-bottom: 10px !important; */
    max-width: 300px !important;
    /* margin-top: 11px; */
  }
  /* .r-1udh08x {
    min-height: 530px !important;
  } */
  .nav_icons_h {
    transition: .3s !important;
    color: #fff;
  }
  .nav_icons_h:hover {
    font-size: 20px !important;
    color: #168cb9;
  }
  .br_10 {
    border-radius: 10px !important;
    overflow: hidden !important;
  }
  .br_15 {
    border-radius: 15px !important;
  }
  .bs_2 {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .my-9 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }

  .btn-three {
    border-radius: 10px !important;
    color: #FFF;
    transition: all 0.5s;
    position: relative;
  }
  .btn-three::before {
    content: '';
      position: absolute;
      top: 0;
      left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: #ffffff1a;
    transition: all 0.3s;
  }
  .btn-three:hover::before {
    opacity: 0 ;
    transform: scale(0.5,0.5);
  }
  .btn-three::after {
    border-radius: 10px !important;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border: 1px solid #168cb980;
    transform: scale(1.2,1.2);
  }
  .btn-three:hover::after {
    opacity: 1;
    transform: scale(1,1);
  }

  @import 'https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300';


/* .box-3 { background-color: #66A182; } */

.btnh {
	line-height: 50px;
	height: 50px;
	text-align: center;
	width: 250px;
	cursor: pointer;
  background-color: #168cb9;
}
#width-100 {
  width: 100% !important;
}
/* #basic-navbar-nav-small {
  display: flex !important;
  align-items: center !important;
  flex-basis: auto !important;
} */
.fs_24 p{
  font-size: 24px !important;
}
.fs_22 p{
  font-size: 22px !important;
}
.fs_20 p{
  font-size: 20px !important;
}
.fs_18 p{
  font-size: 18px !important;
}
.fs_16 p{
  font-size: 16px !important;
}
.fs_14 p{
  font-size: 14px !important;
}
.fs_24 {
  font-size: 24px !important;
}
.fs_22 {
  font-size: 22px !important;
}
.fs_20 {
  font-size: 20px !important;
}
.fs_18 {
  font-size: 18px !important;
}
.fs_16 {
  font-size: 16px !important;
}
.fs_14 {
  font-size: 14px !important;
}
#catNav-links:hover{
  color: #288bb1 !important;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
.home_head_news {
  height: 398px !important;
  overflow: auto !important;
}
.bns-main-dark {
  background-color: #303030 !important;
}
.bg-dark-bcb {
  background-color: #303030 !important;
}
.bg-dark-bcb-skel {
  background-color: #c2c2c2 !important;
}
.slick-prev,
.slick-next {
  display: none !important;
}
.bg-dark-marque {
  background-color: #818181 !important;
}
.dark-nav-sub-menu {
  color: #096a8f !important;
}
.dark-nav-sub-menu:hover {
  color: #fff !important;
}
/* .dark-nav-sub-menu {
  color: #096a8f !important;
} */
.light-nav-sub-menu:hover {
  color: #000 !important;
}
.offcanvas-items-light {
  color: #168cb9 !important;
} 
.offcanvas-items-dark {
  color: #168cb9 !important;
} 
.offcanvas-items-light:hover {
  color: #000 !important;
} 
.offcanvas-items-dark:hover {
  color: #fff !important;
}
.text-light p {
  color: #fff !important;
} 
p {
  font-size: var(--text-size) !important;
}
a {
  font-size: var(--text-size) !important;
}
.li_home {
  height: 99.5px !important;
}
h4 {
  color: #168cb9 !important;
}
#hns-main-logo, #rns-nav-logo {
  color: #168cb9 !important;
}
.scrolling-text {
  white-space: nowrap;
  overflow: hidden;
  animation: scroll 10s linear infinite;
}
.news_view_time {
  display: flex;
  justify-content: space-between;
}

/* @media (max-width: 375px) {
  .news_view_time {
    text-align: end;
  }
} */
/* @media (min-width: 376px) {
  .news_view_time {
    display: flex;
    justify-content: space-between;
  }
} */

